/**
* Returns a CSS variable color for the given name.
* The color will automatically switch between dark and light modes.
*/
@function get-color($color-name) {
    @return var(--color-#{$color-name});
}

/**
* Returns an RGB color from the Dark Mode palette,
* regardless of the browser setting.
*/
@function get-dark-color($color-name) {
    @return map-get($map: $colors-dark, $key: $color-name);
}

/**
* Returns an RGB color from the Light Mode palette,
* regardless of the browser setting.
*/
@function get-light-color($color-name) {
    @return map-get($map: $colors-light, $key: $color-name);
}

$colors-light: (
    primary:   rgba(43, 43, 43, 1),
    secondary: rgba(60, 60, 67, .6),
    tertiary:  rgba(60, 60, 67, .3),
    quaternary:rgba(60, 60, 67, .12),

    system-background: rgba(255, 255, 255, 1),
    system-background-secondary: rgba(249, 249, 252, 1),
    system-background-tertiary: rgba(255, 255, 255, 1),

    vol-blue: rgb(71, 90, 232),
    vol-red: rgb(177, 0, 53),
    vol-green: rgb(75, 200, 138),
    vol-orange: rgb(255, 149, 0),
    vol-blue-background: rgba(71, 90, 232, .1),
    vol-red-background: rgba(177, 0, 53, 0.1),
    vol-green-background: rgba(75, 200, 138, .15),
);

$colors-dark: (
    primary:   rgba(250, 250, 250, 1),
    secondary: rgba(235, 235, 245, .6),
    tertiary:  rgba(235, 235, 245, .3),
    quaternary:rgba(235, 235, 245, .18),

    system-background: rgba(0, 0, 0, 1),
    system-background-secondary: rgba(28, 28, 30, 1),
    system-background-tertiary: rgba(44, 44, 46, 1),

    vol-blue: rgb(100, 118, 255),
    vol-red: rgb(177, 0, 53),
    vol-green: rgb(75, 200, 138),
    vol-orange: rgb(255, 159, 10),
    vol-blue-background: rgba(71, 90, 232, .25),
    vol-red-background: rgba(177, 0, 53, 0.3),
    vol-green-background: rgba(75, 200, 138, .3),
);

/**
* Generating variable for default light mode
*/
:root {
    @each $name, $color in $colors-light {
        --color-#{$name}: #{$color};
    }

    --theme: 'light';
}

@each $name, $color in $colors-light  {
    .text-#{$name} {
        color: #{$color} !important;
    }

    .bg-#{$name} {
        background-color: #{$color} !important;
    }
}

@media(prefers-color-scheme: dark) {
    :root {
        @each $name, $color in $colors-dark {
            --color-#{$name}: #{$color};
        }

        --theme : 'dark';
    }

    @each $name, $color in $colors-dark  {
        .text-#{$name} {
            color: #{$color} !important;
        }

        .bg-#{$name} {
            background-color: #{$color} !important;
        }
    }
}