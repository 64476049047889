.task {
    background-color: get-color(system-background-tertiary);
    border-radius: 10px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    margin-bottom: 1rem;
    padding: 0.75rem 0.75rem 0.25rem 0.75rem;
    width: 100%;
    transition: all ease-in-out 0.1s;
    cursor: pointer;

    .date {
        color: get-color(secondary);
        font-size: 0.87rem;
        font-weight: 600;
    }

    .title {
        font-size: 1rem;
        font-weight: 600;
    }

    .task-details {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin-top: 0.25rem;
        position: relative;

        .pill {
            border-radius: 50px;
            font-size: 0.8rem;
            padding: 0 0.6rem;
            margin-bottom: 0.5rem;
        }

        .spots {
            @extend .pill;

            background-color: get-color(quaternary);
            color: get-color(secondary);
            font-weight: 700;
            position: relative;
            z-index: 2;
            margin-right: 0.5rem;

            &.full {
                background-color: get-color(vol-green);
                color: #fff;
            }

            svg {
                margin-right: 4px;
                margin-bottom: 3px;
            }
        }

        .waiting-list {
            @extend .pill;
            background-color: get-color(vol-green-background);
            color: get-color(vol-green);
            font-weight: 700;
            padding: 0 0.5rem 0 1.5rem;
            position: absolute;
            left: 2.25rem;
            top: 0;
            z-index: 1;
        }

        .category {
            @extend .pill;

            font-size: 0.75rem;
            font-weight: 600;
            line-height: 1.6;
            text-transform: uppercase;

            &.fix-margin {
                margin-left: 2.25rem;
            }
        }
    }

    &.has-warning {
        padding-left: 2.25rem;
        position: relative;

        .date {
            color: get-color(vol-orange);
        }

        &::before {
            content: "";
            background-image: url("../assets/svg/icon-warning-red.svg");
            background-repeat: no-repeat;
            background-size: cover;
            height: 1.25rem;
            left: 0.55rem;
            position: absolute;
            width: 1.25rem;
        }
    }

    &.is-selected {
        background-color: get-color(vol-blue-background);
        color: get-color(vol-blue);
        box-shadow: none;
        border: 0.5px solid;

        .date {
            color: get-color(vol-blue);
        }

        &.has-warning .date {
            color: get-color(vol-blue);
        }

        .waiting-list,
        .category,
        .spots {
            background-color: get-color(vol-blue-background);
            color: get-color(vol-blue);
        }

        .task-details {
            .spots.full {
                background-color: get-color(vol-blue);
            }
        }
    }
}
