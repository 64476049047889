@page {
    size: auto;
    margin: 10mm;
}

@media print {
    .day-card {
        break-inside: avoid;
        page-break-inside: avoid;
    }
}
.print {
    /*
    * Utility classes used in the print
    */

    .caption {
        color: get-light-color(secondary);
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .print-description {
        padding: 0;
        margin: 0;
    }

    .flex-y-align {
        display: flex;
        align-items: center;
    }

    .category-pill {
        display: inline-block;
        width: 3rem;
        height: 0.7rem;
        margin-right: 1rem;
        border-radius: 0.5rem;
        background-color: wheat;

        &.vertical {
            width: 0.7rem;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0.5rem;
        }
    }

    .print-flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    @import "../components/manage-users/TableManageUsers";
}
