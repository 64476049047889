.login-wrapper {
    width: 100%;
    height: 100vh;
    background-color: $volontar-pink;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 20rem;
        opacity: 0;

        // Animation
        -webkit-animation-duration: .5s;
        animation-duration: .5s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;

        @-webkit-keyframes fadeIn {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        @keyframes fadeIn {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        img {
            width: 80%;
            margin-bottom: 2rem;
        }

        h1 {
            font-size: 2.3rem;
        }

        p {
            margin-bottom: 2rem; 
        }

        .btn.btn-default {
            background-color: white;
            color: $volontar-pink;
            display: block;
            width: 80%;
            font-weight: 600;
            font-size: 1.1rem;
            border-radius: 10rem;
        }
    }
}