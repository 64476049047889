.print .day-card {
    border-bottom: 1px solid get-light-color(quaternary);
    padding: 2.5rem 0;
    width: 100%;

    h2 {
        margin-bottom: 0.5rem;
        text-transform: capitalize;
    }

    .pill {
        border-radius: 50px;
        font-size: 0.8rem;
        padding: 0 0.4rem;
        display: inline-block;
    }

    .spots {
        @extend .pill;

        background-color: get-color(quaternary);
        color: get-color(secondary);
        font-weight: 700;
        z-index: 2;
        margin-left: 0.5rem;

        &.full {
            background-color: get-color(vol-green);
            color: #fff;
        }

        svg {
            margin-right: 4px;
            margin-bottom: 3px;
        }
    }

    .event {
        width: 48%;
        position: relative;
        padding-left: 1.5rem;
        margin-bottom: 1rem;

        .event-title {
            font-size: 1.25rem;
        }

        .event-header {
            display: block;
            margin-bottom: 0.5rem;
        }
    }
}
