.groups-container {
    padding-top: 10rem;
    padding-bottom: 4rem;
}

.groups-weeks {
    margin-bottom: 3rem;
    position: relative;

    .title {
        font-size: 1.5rem;
        font-weight: 500;
        color: get-color(secondary);
    }

    .groups-weeks-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .month-container {
            margin-top: 1rem;
            flex: 0 50%;

            .month-row {
                display: flex;
                margin-bottom: 1rem;
            }

            .month {
                text-transform: uppercase;
                margin-bottom: 0.5rem;
                font-size: 1rem;
                letter-spacing: 0.5px;
                font-weight: 500;
                color: get-color(tertiary);
            }
        }
    }
}

.groups-weeks-menu {
    position: absolute;
    top: 0rem;
    left: -9rem;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            cursor: pointer;

            border-radius: 5px;
            background: get-color(quaternary);
            color: get-color(secondary);
            box-shadow: none;

            font-weight: 500;
            font-size: 1.1rem;
            line-height: 1.5rem;
            text-align: center;

            margin-bottom: 0.5rem;
            padding: 0.2rem 2rem;

            &.selected {
                @extend .selected;
            }
        }
    }
}

.selected {
    background-color: get-color(vol-blue-background);
    color: get-color(vol-blue);
    box-shadow: 0 0px 10px rgba(33, 57, 95, 0.08);
    border: .5px solid;
}

.groups-stats {
    display: flex;
    flex-direction: column;

    .group-row {
        display: flex;
        flex-direction: row;
        margin-bottom: 0.2rem;

        .name {
            text-transform: uppercase;
            font-size: 1rem;
            letter-spacing: 0.5px;
            color: rgba($color-dark-grey, 0.6);
            width: 6rem;
        }

        .count {
            font-size: 1rem;
            letter-spacing: 0.5px;
            color: $color-dark-grey;
        }
    }
}

.bar {
    background-color: get-color(system-background-tertiary);
    border-radius: 10px;
    box-shadow: 0 0px 10px rgba(33, 57, 95, 0.08);
    padding: 2rem;
}