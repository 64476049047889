.navbar {
    background-color: get-color(system-background-tertiary);
    border-bottom: .5px solid get-color(tertiary);
    padding: 0;
    user-select: none;

    .container-fluid {
        padding: 0 1rem;
    }

    .navbar-nav {
        flex-flow: row wrap;
    }

    a {
        color: get-color(primary);
        font-weight: 500;
        padding: 0.8rem .5rem;
        margin-right: .5rem;

        &.active {
            color: get-color(vol-blue);
            border-bottom: 2px solid get-color(vol-blue);
        }
    }

    .connected-user {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: .9em;

        .user-name {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-right: .5rem;

            .name {
                font-weight: 500;
            }
            .position {
                color: get-color(secondary);
                text-transform: capitalize;
            }
        }

        .profile-icon {
            width: 2rem;
            color: get-color(vol-blue);
            margin-left: .8rem;
        }
    }

    &.subnav {
        a,
        span {
            color: get-color(secondary);
            font-weight: 400;

            &.active {
                border: 0;
                color: get-color(vol-blue);
                font-weight: 500;
            }
        }
    }

    &.schedule-subnav {
        @extend .subnav;

        .navbar-nav {
            flex-flow: row wrap;
            align-items: center;
            padding: .7rem 0;

            span {
                padding: 1rem .5rem;

                &.custom-check {
                    padding: 0;
                }
            }

            .week-arrow-navigation {
                background-color: get-color(vol-blue-background);
                content: url('../assets/svg/icon-arrow-left-primary.svg');
                width: 2rem;
                height: 2rem;
                border-radius: 50%;
                padding: .5rem;
                margin-right: 1em;
                cursor: pointer;

                &.right {
                    transform: rotate(180deg);
                    margin-left: 1em;
                }
            }

            .btn.btn-default {
                padding: .15rem 0.7rem;
                display: block;
                color: get-color(vol-blue);
                background-color: get-color(vol-blue-background);
            }

            .current-month {
                min-width: 6rem;
                text-align: center;
                text-transform: capitalize;
                cursor: pointer;
            }

            ul {
                list-style: none;
                margin: 0;
                align-items: center;
                justify-content: flex-start;

                li {
                    flex-grow: 0;
                    width: auto;
                    margin-right: 1.25rem;
                }
            }
        }
    }
}

.react-datepicker {
    font-family: $sans-serif-family;

    .react-datepicker__day--selected {
        background-color: get-color(vol-blue);
        border-radius: 50%;
    }

    .react-datepicker__day:hover {
        border-radius: 50%;
    }

    .react-datepicker__current-month {
        color: get-color(vol-blue);
    }

    .react-datepicker__day-names,
    .react-datepicker__day-name {
        color: rgba(get-color(vol-blue), 0.8);
    }
}