.pagination-container {
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;

    .pagination {
        .page-item {
            cursor: pointer;

            &.active {
                .page-link {
                    background-color: get-color(vol-blue);
                    color: $white;
                    border-color: get-color(vol-blue);
                }
            }

            &.disabled {
                cursor: not-allowed;
                .page-link {
                    color: $system-grey-3;
                }
            }

            .page-link {
                color: get-color(secondary);
                background-color: get-color(system-background-tertiary);
                border: 1px solid get-color(quaternary);
            }
        }
    }
}
