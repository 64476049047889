.table-action {
    cursor: pointer;
    margin: 0 0 0 1rem;
    padding-bottom: 0.1rem;
}

.table-action-edit {
    @extend .table-action;

    color: get-color(vol-blue);
    border-bottom: 1px solid;
}

.table-action-delete {
    @extend .table-action;

    color: get-color(vol-red);
    border-bottom: 1px solid;
}

table {
    width: 100%;
    color: get-color(primary);

    thead {
        th {
            padding: 0.8rem 0 0.8rem 1rem;
            color: get-color(secondary);
            font-weight: 500;
            font-size: 0.9rem;
            border-top: 1px solid get-color(quaternary);
            border-bottom: 1px solid get-color(quaternary);

            &:last-child {
                padding-right: 1rem;
            }
        }
    }

    tbody {
        tr {
            td {
                padding: 1rem 0 1rem 1rem;
                border-bottom: 1px solid get-color(quaternary);

                &:last-child {
                    padding-right: 1rem;
                }
            }
        }
    }

    .custom-check-wrapper .custom-check {
        margin-right: 0;
    }
}
