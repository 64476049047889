@import url(https://rsms.me/inter/inter.css);
/*
 * Main SASS file of the app
 * -------------------------
 * It imports the master file "_all.scss" of each sub-dir.
 */
/* Abstracts master .sass file */
/**
* Returns a CSS variable color for the given name.
* The color will automatically switch between dark and light modes.
*/
/**
* Returns an RGB color from the Dark Mode palette,
* regardless of the browser setting.
*/
/**
* Returns an RGB color from the Light Mode palette,
* regardless of the browser setting.
*/
/**
* Generating variable for default light mode
*/
:root {
  --color-primary: #2b2b2b;
  --color-secondary: rgba(60, 60, 67, 0.6);
  --color-tertiary: rgba(60, 60, 67, 0.3);
  --color-quaternary: rgba(60, 60, 67, 0.12);
  --color-system-background: white;
  --color-system-background-secondary: #f9f9fc;
  --color-system-background-tertiary: white;
  --color-vol-blue: #475ae8;
  --color-vol-red: #b10035;
  --color-vol-green: #4bc88a;
  --color-vol-orange: #ff9500;
  --color-vol-blue-background: rgba(71, 90, 232, 0.1);
  --color-vol-red-background: rgba(177, 0, 53, 0.1);
  --color-vol-green-background: rgba(75, 200, 138, 0.15);
  --theme: 'light'; }

.text-primary {
  color: #2b2b2b !important; }

.bg-primary {
  background-color: #2b2b2b !important; }

.text-secondary {
  color: rgba(60, 60, 67, 0.6) !important; }

.bg-secondary {
  background-color: rgba(60, 60, 67, 0.6) !important; }

.text-tertiary {
  color: rgba(60, 60, 67, 0.3) !important; }

.bg-tertiary {
  background-color: rgba(60, 60, 67, 0.3) !important; }

.text-quaternary {
  color: rgba(60, 60, 67, 0.12) !important; }

.bg-quaternary {
  background-color: rgba(60, 60, 67, 0.12) !important; }

.text-system-background {
  color: white !important; }

.bg-system-background {
  background-color: white !important; }

.text-system-background-secondary {
  color: #f9f9fc !important; }

.bg-system-background-secondary {
  background-color: #f9f9fc !important; }

.text-system-background-tertiary {
  color: white !important; }

.bg-system-background-tertiary {
  background-color: white !important; }

.text-vol-blue {
  color: #475ae8 !important; }

.bg-vol-blue {
  background-color: #475ae8 !important; }

.text-vol-red {
  color: #b10035 !important; }

.bg-vol-red {
  background-color: #b10035 !important; }

.text-vol-green {
  color: #4bc88a !important; }

.bg-vol-green {
  background-color: #4bc88a !important; }

.text-vol-orange {
  color: #ff9500 !important; }

.bg-vol-orange {
  background-color: #ff9500 !important; }

.text-vol-blue-background {
  color: rgba(71, 90, 232, 0.1) !important; }

.bg-vol-blue-background {
  background-color: rgba(71, 90, 232, 0.1) !important; }

.text-vol-red-background {
  color: rgba(177, 0, 53, 0.1) !important; }

.bg-vol-red-background {
  background-color: rgba(177, 0, 53, 0.1) !important; }

.text-vol-green-background {
  color: rgba(75, 200, 138, 0.15) !important; }

.bg-vol-green-background {
  background-color: rgba(75, 200, 138, 0.15) !important; }

@media (prefers-color-scheme: dark) {
  :root {
    --color-primary: #fafafa;
    --color-secondary: rgba(235, 235, 245, 0.6);
    --color-tertiary: rgba(235, 235, 245, 0.3);
    --color-quaternary: rgba(235, 235, 245, 0.18);
    --color-system-background: black;
    --color-system-background-secondary: #1c1c1e;
    --color-system-background-tertiary: #2c2c2e;
    --color-vol-blue: #6476ff;
    --color-vol-red: #b10035;
    --color-vol-green: #4bc88a;
    --color-vol-orange: #ff9f0a;
    --color-vol-blue-background: rgba(71, 90, 232, 0.25);
    --color-vol-red-background: rgba(177, 0, 53, 0.3);
    --color-vol-green-background: rgba(75, 200, 138, 0.3);
    --theme: 'dark'; }
  .text-primary {
    color: #fafafa !important; }
  .bg-primary {
    background-color: #fafafa !important; }
  .text-secondary {
    color: rgba(235, 235, 245, 0.6) !important; }
  .bg-secondary {
    background-color: rgba(235, 235, 245, 0.6) !important; }
  .text-tertiary {
    color: rgba(235, 235, 245, 0.3) !important; }
  .bg-tertiary {
    background-color: rgba(235, 235, 245, 0.3) !important; }
  .text-quaternary {
    color: rgba(235, 235, 245, 0.18) !important; }
  .bg-quaternary {
    background-color: rgba(235, 235, 245, 0.18) !important; }
  .text-system-background {
    color: black !important; }
  .bg-system-background {
    background-color: black !important; }
  .text-system-background-secondary {
    color: #1c1c1e !important; }
  .bg-system-background-secondary {
    background-color: #1c1c1e !important; }
  .text-system-background-tertiary {
    color: #2c2c2e !important; }
  .bg-system-background-tertiary {
    background-color: #2c2c2e !important; }
  .text-vol-blue {
    color: #6476ff !important; }
  .bg-vol-blue {
    background-color: #6476ff !important; }
  .text-vol-red {
    color: #b10035 !important; }
  .bg-vol-red {
    background-color: #b10035 !important; }
  .text-vol-green {
    color: #4bc88a !important; }
  .bg-vol-green {
    background-color: #4bc88a !important; }
  .text-vol-orange {
    color: #ff9f0a !important; }
  .bg-vol-orange {
    background-color: #ff9f0a !important; }
  .text-vol-blue-background {
    color: rgba(71, 90, 232, 0.25) !important; }
  .bg-vol-blue-background {
    background-color: rgba(71, 90, 232, 0.25) !important; }
  .text-vol-red-background {
    color: rgba(177, 0, 53, 0.3) !important; }
  .bg-vol-red-background {
    background-color: rgba(177, 0, 53, 0.3) !important; }
  .text-vol-green-background {
    color: rgba(75, 200, 138, 0.3) !important; }
  .bg-vol-green-background {
    background-color: rgba(75, 200, 138, 0.3) !important; } }

/*
* ======
* Colors
* ======
*/
.mono-font {
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace !important; }

body {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #2b2b2b;
  color: var(--color-primary); }

h1, h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0; }

@supports (font-variation-settings: normal) {
  body {
    font-family: 'Inter var', sans-serif; } }

/*
* =============================
* Breakpoints for media queries
* =============================
*/
/* Common master .sass file */
.table-action, .table-action-edit, .table-action-delete {
  cursor: pointer;
  margin: 0 0 0 1rem;
  padding-bottom: 0.1rem; }

.table-action-edit {
  color: #475ae8;
  color: var(--color-vol-blue);
  border-bottom: 1px solid; }

.table-action-delete {
  color: #b10035;
  color: var(--color-vol-red);
  border-bottom: 1px solid; }

table {
  width: 100%;
  color: #2b2b2b;
  color: var(--color-primary); }
  table thead th {
    padding: 0.8rem 0 0.8rem 1rem;
    color: rgba(60, 60, 67, 0.6);
    color: var(--color-secondary);
    font-weight: 500;
    font-size: 0.9rem;
    border-top: 1px solid rgba(60, 60, 67, 0.12);
    border-top: 1px solid var(--color-quaternary);
    border-bottom: 1px solid rgba(60, 60, 67, 0.12);
    border-bottom: 1px solid var(--color-quaternary); }
    table thead th:last-child {
      padding-right: 1rem; }
  table tbody tr td {
    padding: 1rem 0 1rem 1rem;
    border-bottom: 1px solid rgba(60, 60, 67, 0.12);
    border-bottom: 1px solid var(--color-quaternary); }
    table tbody tr td:last-child {
      padding-right: 1rem; }
  table .custom-check-wrapper .custom-check {
    margin-right: 0; }

.custom-check-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin: 0; }
  .custom-check-wrapper input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    cursor: pointer; }
  .custom-check-wrapper .custom-check {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    background-color: white;
    background-color: var(--color-system-background-tertiary);
    border-radius: 0.2rem;
    cursor: pointer;
    padding: 0; }
    .custom-check-wrapper .custom-check.custom-radio {
      border-radius: 25px;
      background-image: none;
      position: relative; }
      .custom-check-wrapper .custom-check.custom-radio.checked {
        background-color: #475ae8;
        background-color: var(--color-vol-blue); }
      .custom-check-wrapper .custom-check.custom-radio .onSelect {
        background-color: #fff;
        position: absolute;
        display: block;
        width: 8px;
        height: 8px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        border-radius: 25px; }
  .custom-check-wrapper.checked.is-minus .custom-check {
    background-image: url(/static/media/minus-solid.3a1b133a.svg) !important; }
  .custom-check-wrapper.checked .custom-check {
    background-color: #475ae8;
    background-color: var(--color-vol-blue);
    border-color: transparent;
    background-image: url(/static/media/icon-check-primary.837199b9.svg);
    background-repeat: no-repeat;
    background-size: 1rem;
    background-position: center; }
    .custom-check-wrapper.checked .custom-check.brown {
      background-image: url(/static/media/icon-check-brown.f72d1e4f.svg);
      background-color: rgba(191, 136, 116, 0.2); }
    .custom-check-wrapper.checked .custom-check.purple {
      background-image: url(/static/media/icon-check-purple.7c6fdc97.svg);
      background-color: rgba(93, 22, 125, 0.2); }
    .custom-check-wrapper.checked .custom-check.light-blue {
      background-image: url(/static/media/icon-check-light-blue.851fb773.svg);
      background-color: rgba(110, 165, 229, 0.2); }

button.btn {
  border: 1px solid transparent;
  padding: 0.5rem 2.75rem;
  border-radius: 5px;
  transition: all ease-in-out .15s; }
  button.btn:hover {
    border-color: transparent; }
  button.btn.btn-primary {
    background-color: rgba(71, 90, 232, 0.1);
    background-color: var(--color-vol-blue-background);
    color: #475ae8;
    color: var(--color-vol-blue); }
  button.btn.btn-primary-inverted {
    background-color: #475ae8;
    background-color: var(--color-vol-blue);
    color: white; }
  button.btn.btn-cancel {
    background-color: white;
    background-color: var(--color-system-background-tertiary);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(60, 60, 67, 0.3);
    border: 1px solid var(--color-tertiary);
    color: #2b2b2b;
    color: var(--color-primary); }
  button.btn.btn-save {
    background-color: #4bc88a;
    background-color: var(--color-vol-green);
    color: white; }
  button.btn.btn-delete {
    background-color: rgba(177, 0, 53, 0.1);
    background-color: var(--color-vol-red-background);
    color: #b10035;
    color: var(--color-vol-red); }
    button.btn.btn-delete:hover, button.btn.btn-delete:focus {
      background-color: #b10035;
      background-color: var(--color-vol-red);
      color: white; }
  button.btn.btn-table {
    padding: 0.5rem 1rem; }
  button.btn.btn-table-small {
    padding: 0.1rem 1rem;
    margin-left: 0.5rem; }
  button.btn:focus {
    outline: none;
    box-shadow: none; }
  button.btn.btn-inline {
    padding: 0 0.5rem;
    font-size: 1.5rem;
    line-height: 1.5;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.5rem;
    min-width: 2.5rem; }
    button.btn.btn-inline span {
      font-size: 1.5rem;
      margin: 0 0.5rem 0 0; }
    button.btn.btn-inline.add {
      background: rgba(71, 90, 232, 0.1);
      background: var(--color-vol-blue-background);
      color: #475ae8;
      color: var(--color-vol-blue);
      font-size: 1.9rem; }
      button.btn.btn-inline.add span {
        margin: 0; }
    button.btn.btn-inline.confirm {
      background: rgba(75, 200, 138, 0.15);
      background: var(--color-vol-green-background);
      color: #4bc88a;
      color: var(--color-vol-green);
      font-size: 1rem; }
  button.btn.btn-toggle {
    color: rgba(60, 60, 67, 0.6);
    color: var(--color-secondary);
    background-color: rgba(60, 60, 67, 0.12);
    background-color: var(--color-quaternary);
    border: 0.5px solid rgba(60, 60, 67, 0.3);
    border: 0.5px solid var(--color-tertiary);
    padding: 1rem 2rem;
    text-align: left;
    font-weight: 600;
    width: 49%; }
    button.btn.btn-toggle.active {
      color: #475ae8;
      color: var(--color-vol-blue);
      background-color: rgba(71, 90, 232, 0.1);
      background-color: var(--color-vol-blue-background);
      border-color: #475ae8;
      border-color: var(--color-vol-blue); }
    button.btn.btn-toggle svg {
      width: 25px; }

.Dropdown-control {
  border-radius: 4px;
  border: 1px solid rgba(60, 60, 67, 0.12);
  border: 1px solid var(--color-quaternary);
  background-color: white;
  background-color: var(--color-system-background-tertiary);
  padding: .35rem 3rem .35rem .75rem;
  color: #2b2b2b;
  color: var(--color-primary); }
  .Dropdown-control .Dropdown-arrow {
    top: 16px; }

.Dropdown-menu {
  border: 1px solid #f9f9fc;
  border: 1px solid var(--color-system-background-secondary);
  background-color: #f9f9fc;
  background-color: var(--color-system-background-secondary); }
  .Dropdown-menu .Dropdown-option {
    color: #2b2b2b;
    color: var(--color-primary); }
    .Dropdown-menu .Dropdown-option.is-selected, .Dropdown-menu .Dropdown-option:hover {
      background-color: rgba(71, 90, 232, 0.1);
      background-color: var(--color-vol-blue-background); }

.Dropdown-root {
  flex-grow: 1; }

.user-image {
  width: 50px;
  height: 50px;
  border-radius: 2rem;
  overflow: hidden;
  background-color: #475ae8;
  background-color: var(--color-vol-blue);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem; }
  .user-image span {
    color: white;
    font-size: 1.1rem;
    font-weight: 500; }

/* Components master .sass file */
.task {
  background-color: white;
  background-color: var(--color-system-background-tertiary);
  border-radius: 10px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  margin-bottom: 1rem;
  padding: 0.75rem 0.75rem 0.25rem 0.75rem;
  width: 100%;
  transition: all ease-in-out 0.1s;
  cursor: pointer; }
  .task .date {
    color: rgba(60, 60, 67, 0.6);
    color: var(--color-secondary);
    font-size: 0.87rem;
    font-weight: 600; }
  .task .title {
    font-size: 1rem;
    font-weight: 600; }
  .task .task-details {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 0.25rem;
    position: relative; }
    .task .task-details .pill, .task .task-details .spots, .task .task-details .waiting-list, .task .task-details .category {
      border-radius: 50px;
      font-size: 0.8rem;
      padding: 0 0.6rem;
      margin-bottom: 0.5rem; }
    .task .task-details .spots {
      background-color: rgba(60, 60, 67, 0.12);
      background-color: var(--color-quaternary);
      color: rgba(60, 60, 67, 0.6);
      color: var(--color-secondary);
      font-weight: 700;
      position: relative;
      z-index: 2;
      margin-right: 0.5rem; }
      .task .task-details .spots.full {
        background-color: #4bc88a;
        background-color: var(--color-vol-green);
        color: #fff; }
      .task .task-details .spots svg {
        margin-right: 4px;
        margin-bottom: 3px; }
    .task .task-details .waiting-list {
      background-color: rgba(75, 200, 138, 0.15);
      background-color: var(--color-vol-green-background);
      color: #4bc88a;
      color: var(--color-vol-green);
      font-weight: 700;
      padding: 0 0.5rem 0 1.5rem;
      position: absolute;
      left: 2.25rem;
      top: 0;
      z-index: 1; }
    .task .task-details .category {
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 1.6;
      text-transform: uppercase; }
      .task .task-details .category.fix-margin {
        margin-left: 2.25rem; }
  .task.has-warning {
    padding-left: 2.25rem;
    position: relative; }
    .task.has-warning .date {
      color: #ff9500;
      color: var(--color-vol-orange); }
    .task.has-warning::before {
      content: "";
      background-image: url(/static/media/icon-warning-red.44d24c52.svg);
      background-repeat: no-repeat;
      background-size: cover;
      height: 1.25rem;
      left: 0.55rem;
      position: absolute;
      width: 1.25rem; }
  .task.is-selected {
    background-color: rgba(71, 90, 232, 0.1);
    background-color: var(--color-vol-blue-background);
    color: #475ae8;
    color: var(--color-vol-blue);
    box-shadow: none;
    border: 0.5px solid; }
    .task.is-selected .date {
      color: #475ae8;
      color: var(--color-vol-blue); }
    .task.is-selected.has-warning .date {
      color: #475ae8;
      color: var(--color-vol-blue); }
    .task.is-selected .waiting-list,
    .task.is-selected .category,
    .task.is-selected .spots {
      background-color: rgba(71, 90, 232, 0.1);
      background-color: var(--color-vol-blue-background);
      color: #475ae8;
      color: var(--color-vol-blue); }
    .task.is-selected .task-details .spots.full {
      background-color: #475ae8;
      background-color: var(--color-vol-blue); }

.week-card {
  background-color: white;
  background-color: var(--color-system-background-tertiary);
  border-radius: 10px;
  box-shadow: 0 0px 10px rgba(33, 57, 95, 0.08);
  padding: .75rem;
  width: 5rem;
  margin-right: 1rem;
  position: relative;
  cursor: pointer; }
  .week-card .week, .week-card .group {
    border-radius: 5px;
    font-size: 1rem;
    height: 1.5rem;
    line-height: 1.5rem; }
  .week-card .week {
    background: white;
    background: var(--color-system-background-tertiary);
    text-align: center;
    color: rgba(60, 60, 67, 0.6);
    color: var(--color-secondary);
    margin-bottom: 0.5rem;
    font-weight: 500; }
  .week-card .group {
    background: rgba(75, 200, 138, 0.15);
    background: var(--color-vol-green-background);
    text-align: center;
    color: #4BC88A;
    font-weight: 500; }
    .week-card .group.warning {
      background: rgba(255, 149, 0, 0.2); }
    .week-card .group p {
      margin: 0;
      padding: 0;
      line-height: 1.3rem;
      height: 1rem; }
    .week-card .group p img {
      color: #ff9500;
      color: var(--color-vol-orange);
      width: 100%;
      height: 100%; }
  .week-card .select-group {
    margin: 0;
    padding: 0 .75rem .4rem .75rem;
    position: absolute;
    top: 72px;
    left: 0;
    right: 0;
    background: white;
    background: var(--color-system-background-tertiary);
    z-index: 1000;
    list-style: none;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0 6px 10px rgba(33, 57, 95, 0.08);
    transition: all .3s;
    max-height: 400px; }
    .week-card .select-group .group {
      background-color: rgba(71, 90, 232, 0.1);
      background-color: var(--color-vol-blue-background);
      color: #475ae8;
      color: var(--color-vol-blue);
      margin-bottom: 0.26rem;
      opacity: 1;
      visibility: visible;
      transition-property: opacity, background, color;
      transition-duration: .3s, .2s, .1s; }
      .week-card .select-group .group:hover {
        background: rgba(75, 200, 138, 0.2);
        color: #4BC88A; }
      .week-card .select-group .group.trash {
        background: rgba(177, 0, 54, 0.15); }
        .week-card .select-group .group.trash img {
          height: 19px;
          padding-bottom: 5px; }
        .week-card .select-group .group.trash:hover {
          background: rgba(177, 0, 54, 0.1); }
    .week-card .select-group.hidden {
      z-index: 0;
      max-height: 0px;
      box-shadow: none; }
      .week-card .select-group.hidden .group {
        visibility: hidden;
        opacity: 0; }

.slide-overview {
  overflow: hidden;
  overflow-y: scroll;
  position: fixed;
  background-color: white;
  background-color: var(--color-system-background-tertiary);
  box-shadow: -0.2rem 0rem 0.6rem 0rem rgba(44, 63, 114, 0.1);
  padding: 6.3rem 1rem 1rem 1rem;
  top: 0rem;
  right: -32rem;
  height: 100vh;
  width: 30rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  transition: all ease-in-out .3s;
  z-index: 10; }
  @media (prefers-color-scheme: dark) {
    .slide-overview {
      box-shadow: none;
      border-left: 0.5px solid rgba(60, 60, 67, 0.12);
      border-left: 0.5px solid var(--color-quaternary); } }
  .slide-overview.active {
    right: 0; }
  .slide-overview .custom-check-wrapper.checked .custom-check {
    border-color: #475ae8;
    border-color: var(--color-vol-blue);
    background-size: 0.8rem;
    background-color: rgba(71, 90, 232, 0.1);
    background-color: var(--color-vol-blue-background);
    background-image: url(/static/media/icon-check-blue.6b25e2c6.svg); }
  .slide-overview .custom-check-wrapper .custom-check {
    width: 1.3rem;
    height: 1.3rem;
    border: 1px solid rgba(60, 60, 67, 0.3);
    border: 1px solid var(--color-tertiary); }
  .slide-overview .form-check label {
    padding-left: 1.25rem;
    margin: 0; }
    .slide-overview .form-check label .form-check-input {
      margin-top: .2rem; }
  .slide-overview .instructions {
    font-size: .9rem;
    color: rgba(60, 60, 67, 0.6);
    color: var(--color-secondary); }
  .slide-overview .notification-preview {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 2rem auto;
    padding: .9rem;
    border-radius: 10px;
    background-color: #f9f9fc;
    background-color: var(--color-system-background-secondary);
    font-size: .9rem;
    box-shadow: 0 0px 10px rgba(33, 57, 95, 0.15); }
    .slide-overview .notification-preview .app {
      display: flex;
      justify-content: space-between;
      width: 100%;
      color: rgba(60, 60, 67, 0.3);
      color: var(--color-tertiary); }
      .slide-overview .notification-preview .app img {
        width: 24px;
        margin-right: 12px;
        border-radius: 7px; }
      .slide-overview .notification-preview .app .name {
        text-transform: uppercase;
        letter-spacing: 1px; }
    .slide-overview .notification-preview .title {
      font-weight: 600; }

.slide-overview-section, .slide-overview section, .slide-overview-head, .slide-overview-desc, .slide-overview-manager, .slide-overview-spots, .slide-overview-controls {
  padding: 1.5rem 0;
  border-bottom: 0.5px solid rgba(60, 60, 67, 0.3);
  border-bottom: 0.5px solid var(--color-tertiary); }
  .slide-overview-section h2, .slide-overview section h2, .slide-overview-head h2, .slide-overview-desc h2, .slide-overview-manager h2, .slide-overview-spots h2, .slide-overview-controls h2 {
    font-weight: 600;
    font-size: 1.5rem;
    margin-top: 0; }
  .slide-overview-section h3, .slide-overview section h3, .slide-overview-head h3, .slide-overview-desc h3, .slide-overview-manager h3, .slide-overview-spots h3, .slide-overview-controls h3 {
    font-size: 1.1rem;
    font-weight: 500;
    margin-top: 0; }
  .slide-overview-section p, .slide-overview section p, .slide-overview-head p, .slide-overview-desc p, .slide-overview-manager p, .slide-overview-spots p, .slide-overview-controls p {
    margin-bottom: 0; }

.slide-overview-controls .btn {
  flex-basis: 49%; }
  .slide-overview-controls .btn.edit, .slide-overview-controls .btn.copy, .slide-overview-controls .btn.remove {
    flex-basis: 32%; }

.select-user-modal {
  width: 100%;
  height: 100%;
  background-color: rgba(43, 43, 43, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1030; }

.modal-container {
  height: 80vh;
  max-height: 800px;
  width: 100%;
  max-width: 1200px;
  background-color: #EFEFEF;
  overflow: hidden;
  border-radius: 0.6rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start; }
  .modal-container .row {
    flex-grow: 1;
    align-items: stretch;
    max-height: calc(100% - 6.5rem);
    overflow: hidden; }
    .modal-container .row .col-8, .modal-container .row .col-4 {
      height: 100%; }

.modal-head {
  border-bottom: 0.5px solid #DDDDDD;
  padding: 0.8rem 1rem; }
  .modal-head h2 {
    margin: 0px;
    font-size: 1.1rem;
    font-weight: 600; }

.modal-col-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%; }
  .modal-col-wrapper .container-fluid {
    flex-grow: 1;
    background-color: #fff;
    border-right: 0.5px solid #DDDDDD;
    overflow: hidden; }
    .modal-col-wrapper .container-fluid .row {
      height: 100%;
      max-height: calc(80vh - 10.7rem); }
      .modal-col-wrapper .container-fluid .row .col-4, .modal-col-wrapper .container-fluid .row .col-8 {
        padding-right: 0; }

.modal-search {
  width: 100%;
  padding: 0.8rem 1rem;
  border-bottom: 0.5px solid #DDDDDD;
  border-right: 0.5px solid #DDDDDD;
  background-color: #fff; }
  .modal-search input.modal-search-input {
    height: 2.5rem;
    font-size: 1rem;
    border-width: .5px;
    border-color: #EFEFEF; }
    .modal-search input.modal-search-input::-webkit-input-placeholder {
      color: #DDDDDD; }
    .modal-search input.modal-search-input::-moz-placeholder {
      color: #DDDDDD; }
    .modal-search input.modal-search-input:-ms-input-placeholder {
      color: #DDDDDD; }
    .modal-search input.modal-search-input:-moz-placeholder {
      color: #DDDDDD; }

.modal-col-row {
  flex-grow: 1;
  background-color: #fff;
  border-right: 0.5px solid #DDDDDD; }

.modal-user-filter {
  border-right: 0.5px solid #DDDDDD;
  padding: 0;
  max-height: calc(80vh - 10.7rem);
  overflow-y: scroll; }

ul.filter-list {
  margin: 0;
  padding: 0.8rem 0;
  max-height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; }
  ul.filter-list li.filter {
    list-style: none;
    padding: 0.5rem 0; }
    ul.filter-list li.filter:nth-child(1) {
      padding-top: 0; }
    ul.filter-list li.filter span {
      color: #6A6A6C;
      transition: color 0.2s ease-in-out;
      padding: .3rem 1rem;
      font-size: 1rem;
      cursor: pointer; }
      ul.filter-list li.filter span:hover {
        text-decoration: none;
        font-weight: 600; }
    ul.filter-list li.filter.current span {
      font-weight: 600;
      color: #475AE8;
      border-left: 5px solid;
      padding: 0.3rem calc(1rem - 5px); }

.modal-user-list {
  overflow-y: scroll;
  max-height: calc(80vh - 10.7rem); }

ul.user-list {
  padding: 0.8rem 1rem 0 0;
  margin: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; }
  ul.user-list li.user {
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1rem; }
    ul.user-list li.user.spot {
      margin-bottom: 0; }
    ul.user-list li.user .user-image {
      width: 2rem;
      height: 2rem;
      border-radius: 2rem;
      overflow: hidden;
      background-color: rgba(71, 90, 232, 0.1);
      background-color: var(--color-vol-blue-background);
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 0; }
      ul.user-list li.user .user-image span,
      ul.user-list li.user .user-image svg {
        color: #475ae8;
        color: var(--color-vol-blue);
        font-size: 0.8rem; }
      ul.user-list li.user .user-image svg {
        width: 1rem;
        margin: 0; }
    ul.user-list li.user .user-name {
      margin-left: 0.8rem;
      flex-grow: 1; }
    ul.user-list li.user .user-task {
      height: 1.6rem;
      border-radius: 1.6rem;
      line-height: 1.6rem;
      text-align: center;
      font-size: 0.9rem;
      color: #6A6A6C;
      margin-right: 1rem; }
      ul.user-list li.user .user-task.active {
        color: #2B2B2B; }
    ul.user-list li.user .user-label {
      display: inline-block;
      padding: 0.1rem;
      width: 6.5rem;
      text-align: center;
      font-size: 0.9rem;
      border-radius: 0.4rem;
      background-color: #EFEFEF;
      color: #6A6A6C;
      text-transform: uppercase; }
      ul.user-list li.user .user-label.active {
        background-color: rgba(75, 200, 138, 0.2);
        color: #4BC88A; }
    ul.user-list li.user .user-check {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-bottom: 0rem;
      margin-right: 0rem;
      margin-left: 10px;
      -webkit-user-select: none;
      user-select: none; }
      ul.user-list li.user .user-check input {
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        cursor: pointer; }
      ul.user-list li.user .user-check .custom-check {
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        background-color: #EFEFEF;
        border-radius: 0.2rem;
        border: 0.1rem solid #DDDDDD;
        cursor: pointer; }
      ul.user-list li.user .user-check.checked .custom-check {
        background-color: rgba(71, 90, 232, 0.2);
        border-color: transparent;
        background-image: url(/static/media/icon-check-primary.837199b9.svg);
        background-repeat: no-repeat;
        background-size: 1rem;
        background-position: center; }

.modal-selected-user {
  padding: 0.8rem 1rem 0.8rem 0rem;
  margin-left: -15px;
  height: 100%;
  height: calc(80vh - 6.5rem);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start; }
  .modal-selected-user h3 {
    color: #6A6A6C;
    font-size: 0.9rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0rem; }

.modal-description {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  min-height: 40%; }
  .modal-description label {
    color: #6A6A6C;
    font-size: 0.9rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0.25rem; }
  .modal-description textarea.modal-textarea {
    width: 100%;
    resize: none;
    height: 6rem;
    font-size: 1rem;
    border-width: .5px;
    border-color: #DDDDDD; }
    .modal-description textarea.modal-textarea::-webkit-input-placeholder {
      color: #DDDDDD; }
    .modal-description textarea.modal-textarea::-moz-placeholder {
      color: #DDDDDD; }
    .modal-description textarea.modal-textarea:-ms-input-placeholder {
      color: #DDDDDD; }
    .modal-description textarea.modal-textarea:-moz-placeholder {
      color: #DDDDDD; }
  .modal-description .Dropdown-menu {
    position: static; }
  .modal-description .dropdown button.dropdown-toggle {
    background-color: #fff;
    border: 0.5px solid #DDDDDD;
    height: 2.7rem;
    line-height: 2.6rem;
    padding: 0 1rem;
    margin: 0;
    border-radius: 0.4rem;
    text-align: left;
    color: #DDDDDD; }
    .modal-description .dropdown button.dropdown-toggle:after {
      width: 1.5rem;
      height: 2.7rem;
      float: right;
      margin: 0;
      background-image: url(/static/media/icon-arrow-down-grey.bd6a0dae.svg);
      background-repeat: no-repeat;
      background-size: 1rem;
      background-position: center;
      border: none; }
    .modal-description .dropdown button.dropdown-toggle:hover, .modal-description .dropdown button.dropdown-toggle:focus {
      outline: none; }

.modal-footer {
  background-color: #fff;
  border-top: 0.5px solid #DDDDDD;
  padding: .5rem 1rem;
  height: 3.5rem; }
  .modal-footer button.footer-btn {
    background-color: #EFEFEF;
    border: 0.5px solid #DDDDDD;
    border-radius: 0.2rem;
    height: 2rem;
    padding: 0rem 1rem;
    font-size: 0.9rem;
    transition: background-color 0.2s ease-in-out; }
    .modal-footer button.footer-btn.cancel:hover, .modal-footer button.footer-btn.cancel:focus {
      outline: none;
      background-color: #DDDDDD; }
    .modal-footer button.footer-btn.save {
      background-color: #4BC88A;
      border-color: #47B57E;
      color: #fff; }
      .modal-footer button.footer-btn.save:hover, .modal-footer button.footer-btn.save:focus {
        outline: none;
        background-color: #47B57E; }

.select-all {
  background-color: #EFEFEF;
  border: 0.5px solid #DDDDDD;
  border-radius: 0.2rem;
  height: 2rem;
  padding: 0rem 1rem;
  font-size: 0.9rem;
  transition: background-color 0.2s ease-in-out;
  margin-top: 10px;
  margin-right: 30px;
  float: right; }

.select-all-clear {
  clear: both; }

.user-table-header, .category-table-header {
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .user-table-header .user-dropdown-wrapper, .category-table-header .user-dropdown-wrapper {
    margin-right: 0.8rem; }

.btn.btn-primary.btn-table.add-user {
  background-image: url(/static/media/icon-plus-primary.eb5e4b8a.svg);
  background-repeat: no-repeat;
  background-size: 1rem;
  background-position: 0.8rem center;
  padding-left: 2.25rem;
  margin-left: 1rem; }

.btn.btn-primary.btn-table:hover {
  background-color: rgba(71, 90, 232, 0.2); }

input.user-table-search {
  margin-left: auto;
  height: 2.5rem;
  width: 30%;
  line-height: 2.5rem;
  font-size: 1rem;
  border-width: .5px;
  border-color: #EFEFEF; }
  input.user-table-search::-webkit-input-placeholder {
    color: #c7c7cc; }
  input.user-table-search::placeholder {
    color: #c7c7cc; }

.user-table-container {
  width: 100%;
  overflow-x: scroll; }

.table.user-table {
  margin-top: 0.8rem;
  color: #2B2B2B;
  width: 100%; }
  .table.user-table * {
    border-color: rgba(71, 90, 232, 0.1); }
  .table.user-table thead th {
    font-size: 1rem;
    border-width: 1px;
    white-space: nowrap; }
    .table.user-table thead th:nth-last-child(1) {
      text-align: right; }
    .table.user-table thead th span {
      display: inline-block;
      padding-right: 1rem;
      cursor: pointer; }
    .table.user-table thead th p {
      cursor: pointer;
      display: inline-block;
      margin-bottom: 0px; }
    .table.user-table thead th p img {
      width: 7px; }
  .table.user-table tbody tr:last-child {
    border-bottom-width: 1px;
    border-bottom-style: solid; }

.user-table-row td {
  font-size: .9rem;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  vertical-align: middle;
  transition: all 0.2s ease-in-out;
  white-space: nowrap; }
  .user-table-row td:nth-last-child(1) {
    text-align: right;
    height: 2.5rem; }

.user-table-row .userlevel {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start; }
  .user-table-row .userlevel .category-title {
    background: #EFEFEF;
    color: #a3a3a3;
    max-width: auto;
    text-transform: uppercase;
    font-size: 0.7rem;
    font-weight: 600;
    padding: 0.1rem 0.3rem;
    text-align: center;
    border-radius: 5px; }

.user-table-row .user-group {
  display: inline-block;
  height: 2rem;
  padding: 0 0.5rem;
  border-radius: 2rem;
  line-height: 2rem;
  text-align: center;
  background-color: #EFEFEF; }

.user-table-row .user-task {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  line-height: 2rem;
  text-align: center;
  font-size: 1.1rem; }
  .user-table-row .user-task.total {
    margin-right: 0.8rem; }
  .user-table-row .user-task.purple {
    background-color: rgba(93, 22, 125, 0.2);
    color: #5D167D;
    -webkit-transform: scale(0.8);
            transform: scale(0.8); }
  .user-table-row .user-task.brown {
    background-color: rgba(191, 136, 116, 0.2);
    color: #BF8874;
    -webkit-transform: scale(0.8);
            transform: scale(0.8); }
  .user-table-row .user-task.light-blue {
    background-color: rgba(110, 165, 229, 0.2);
    color: #6EA5E5;
    -webkit-transform: scale(0.8);
            transform: scale(0.8); }

.user-table-row .user-edit-btn {
  background: transparent;
  border: none;
  text-decoration: underline;
  color: #475AE8;
  font-size: 0.9rem;
  transition: opacity 0.2s ease-in-out;
  padding: 0; }
  .user-table-row .user-edit-btn:hover, .user-table-row .user-edit-btn:focus {
    outline: none;
    opacity: 0.8; }

.user-table-row .user-group-input, .user-table-row .btn-wrapper {
  display: none; }

.user-table-row .custom-check-wrapper .custom-check {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0rem;
  margin-top: 0.4rem; }

.user-table-row .custom-check-wrapper.checked .custom-check {
  background-size: 0.8rem; }

.user-table-row.mode-edit {
  background-color: #fff;
  border: 1px solid #EFEFEF; }
  .user-table-row.mode-edit td:nth-last-child(1) {
    height: 8rem;
    vertical-align: bottom; }
  .user-table-row.mode-edit .user-group-input,
  .user-table-row.mode-edit .btn-wrapper {
    display: flex;
    justify-content: space-around; }
  .user-table-row.mode-edit .user-group-input {
    width: 6rem;
    border-width: 1px;
    padding-left: 0.5rem; }
  .user-table-row.mode-edit .user-group, .user-table-row.mode-edit .user-edit-btn {
    display: none; }

.pagination-container {
  display: flex;
  justify-content: center;
  margin-bottom: 4rem; }
  .pagination-container .pagination .page-item {
    cursor: pointer; }
    .pagination-container .pagination .page-item.active .page-link {
      background-color: #475ae8;
      background-color: var(--color-vol-blue);
      color: #fff;
      border-color: #475ae8;
      border-color: var(--color-vol-blue); }
    .pagination-container .pagination .page-item.disabled {
      cursor: not-allowed; }
      .pagination-container .pagination .page-item.disabled .page-link {
        color: #c7c7cc; }
    .pagination-container .pagination .page-item .page-link {
      color: rgba(60, 60, 67, 0.6);
      color: var(--color-secondary);
      background-color: white;
      background-color: var(--color-system-background-tertiary);
      border: 1px solid rgba(60, 60, 67, 0.12);
      border: 1px solid var(--color-quaternary); }

.loading-container {
  height: 100vh;
  width: 100%;
  display: flex;
  background: #f9f9fc;
  background: var(--color-system-background-secondary);
  justify-content: center;
  flex-direction: column;
  align-items: center; }
  .loading-container .spinner {
    margin-top: 2rem;
    width: 90px; }

.loader-container {
  height: calc(100vh - 250px);
  display: flex;
  justify-content: center;
  align-items: center; }
  .loader-container img {
    width: 90px; }

.login-page {
  background: #f9f9fc;
  background: var(--color-system-background-secondary);
  height: 100vh; }
  .login-page * {
    padding: 0;
    box-sizing: border-box; }
  .login-page .header {
    position: absolute;
    top: -226px;
    right: 0;
    left: calc((100vw - 585px) / 2);
    background: url(/static/media/header.7419f31f.svg) no-repeat;
    height: 316px;
    z-index: 0; }
  .login-page header {
    z-index: 1;
    position: relative;
    width: 100%;
    text-align: center;
    padding-top: 1.8rem; }
    .login-page header img {
      width: 146px; }
  .login-page .container {
    margin-top: 3rem;
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 72vh; }
    @media (min-width: 768px) {
      .login-page .container {
        height: 87vh; } }
  .login-page .organisation {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; }
    .login-page .organisation .organisation-title {
      font-size: 1.3rem;
      color: #2b2b2b;
      color: var(--color-primary);
      font-weight: normal;
      margin-bottom: 1rem; }
      .login-page .organisation .organisation-title b {
        font-weight: bold; }
      .login-page .organisation .organisation-title.is-success {
        color: #4bc88a;
        color: var(--color-vol-green);
        margin-top: 1.1rem; }
    .login-page .organisation .organisation-logo {
      margin-bottom: 1rem;
      width: 100px;
      max-height: 120px; }
      .login-page .organisation .organisation-logo img {
        width: 100%;
        height: 100%; }
      .login-page .organisation .organisation-logo.hide {
        opacity: 0; }
    .login-page .organisation .check-success {
      width: 60px;
      margin-top: 3rem; }
    .login-page .organisation .button-login {
      text-align: center;
      color: white;
      width: 150px; }
      .login-page .organisation .button-login:hover {
        color: white;
        text-decoration: none; }
  .login-page .policy {
    color: rgba(60, 60, 67, 0.3);
    color: var(--color-tertiary);
    text-align: center;
    font-size: 0.8rem; }
    .login-page .policy .button-pingst {
      display: block;
      font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      font-size: 1rem;
      background-color: #475ae8;
      background-color: var(--color-vol-blue);
      width: 290px;
      color: white;
      font-weight: 400;
      border-radius: 10px;
      height: 40px;
      line-height: 40px;
      border: none;
      margin: 0 auto 1rem auto;
      cursor: pointer; }
      .login-page .policy .button-pingst .button-pingst-icon {
        margin-left: 0.8rem;
        width: 12px;
        fill: white; }
      .login-page .policy .button-pingst:hover {
        text-decoration: none; }
    .login-page .policy .transition {
      margin-bottom: 1rem; }
      .login-page .policy .transition span {
        color: #2b2b2b;
        color: var(--color-primary);
        text-transform: uppercase;
        font-size: 0.9rem;
        font-weight: 600;
        padding-left: 1rem;
        padding-right: 1rem; }
      .login-page .policy .transition div {
        width: 103px;
        display: inline-block;
        vertical-align: middle;
        height: 1px;
        background: #2b2b2b;
        background: var(--color-primary); }
  .login-page .form-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; }
    @media (min-width: 768px) {
      .login-page .form-login {
        width: 300px; } }
    .login-page .form-login .forgot-password {
      margin-top: 2rem;
      opacity: 0;
      cursor: pointer;
      margin-bottom: 0rem; }
      .login-page .form-login .forgot-password a {
        color: #2b2b2b;
        color: var(--color-primary);
        font-size: 0.9rem;
        font-weight: 400; }
        .login-page .form-login .forgot-password a span {
          padding-top: 4px; }
        .login-page .form-login .forgot-password a:hover {
          color: #2b2b2b;
          color: var(--color-primary);
          text-decoration: none; }
      .login-page .form-login .forgot-password.show {
        opacity: 1; }
      .login-page .form-login .forgot-password .forgot-password-icon {
        margin-left: 0.5rem;
        width: 12px;
        fill: #2b2b2b;
        fill: var(--color-primary);
        display: inline-block; }
    .login-page .form-login .error-message {
      color: #b10035;
      color: var(--color-vol-red);
      text-align: left;
      width: 100%;
      font-size: 0.7rem;
      text-transform: uppercase;
      font-weight: 600;
      margin-top: 0.5rem;
      padding-left: 0.8rem;
      margin-bottom: 0.5rem; }
    .login-page .form-login input:first-child {
      border-radius: 6px 6px 0px 0px; }
    .login-page .form-login input:last-child {
      border-radius: 0px 0px 6px 6px; }
    .login-page .form-login .input-container {
      position: relative;
      width: 100%; }
      .login-page .form-login .input-container .arrow-right {
        width: 1.6rem;
        position: absolute;
        right: 0.5rem;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        fill: #475ae8;
        fill: var(--color-vol-blue);
        cursor: pointer; }
        .login-page .form-login .input-container .arrow-right.has-error {
          fill: #b10035;
          fill: var(--color-vol-red); }
      .login-page .form-login .input-container input {
        font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        background: white;
        background: var(--color-system-background-tertiary);
        color: #2b2b2b;
        color: var(--color-primary);
        border: 1px solid rgba(60, 60, 67, 0.3);
        border: 1px solid var(--color-tertiary);
        height: 46px;
        font-size: 1rem;
        padding: 0 0.8rem;
        font-weight: 400;
        -webkit-appearance: none;
        width: 100%;
        outline: none; }
        .login-page .form-login .input-container input:focus {
          outline: 0; }
        .login-page .form-login .input-container input::-webkit-input-placeholder {
          text-transform: none;
          color: rgba(60, 60, 67, 0.12);
          color: var(--color-quaternary); }
        .login-page .form-login .input-container input::placeholder {
          text-transform: none;
          color: rgba(60, 60, 67, 0.12);
          color: var(--color-quaternary); }
        .login-page .form-login .input-container input.field-is-alone {
          border-radius: 6px; }
        .login-page .form-login .input-container input.field-is-first {
          border-radius: 6px 6px 0px 0px;
          border-bottom: 0; }
        .login-page .form-login .input-container input.field-is-last {
          border-top-color: rgba(60, 60, 67, 0.12);
          border-top-color: var(--color-quaternary);
          border-radius: 0px 0px 6px 6px;
          height: 0px;
          opacity: 0; }
        .login-page .form-login .input-container input.field-transition {
          transition: 0.2s all;
          height: 46px;
          opacity: 1; }
        .login-page .form-login .input-container input.field-has-error {
          background: rgba(177, 0, 53, 0.1);
          color: #b10035;
          color: var(--color-vol-red);
          border-color: #b10035;
          border-color: var(--color-vol-red); }
  .login-page .button-login {
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    margin-top: 2rem;
    font-size: 1rem;
    background: #475ae8;
    background: var(--color-vol-blue);
    width: 120px;
    color: white;
    font-weight: 600;
    border-radius: 30px;
    height: 40px;
    line-height: 40px;
    border: none;
    opacity: 0;
    text-transform: uppercase;
    cursor: pointer; }
    .login-page .button-login.button-show {
      opacity: 1;
      transition: 0.2s all; }
    .login-page .button-login:focus {
      outline: 0;
      border: none; }

.create-password .organisation .organisation-title {
  margin-top: 3rem;
  margin-bottom: 2rem;
  text-align: center; }

.create-password .form-login .button-login {
  width: 130px; }

.account-modal {
  position: absolute;
  right: 1rem;
  width: 320px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background: white;
  background: var(--color-system-background-tertiary);
  border: 1px solid rgba(60, 60, 67, 0.12);
  border: 1px solid var(--color-quaternary);
  top: calc(56px + 1rem);
  z-index: 10;
  display: flex;
  align-items: center;
  flex-direction: column; }
  .account-modal .account-info {
    width: 100%;
    padding: 1rem 0;
    border-bottom: 1px solid rgba(60, 60, 67, 0.12);
    border-bottom: 1px solid var(--color-quaternary);
    display: flex;
    align-items: center;
    flex-direction: column; }
    .account-modal .account-info .user-name {
      font-weight: 600;
      font-size: 1rem;
      color: #2b2b2b;
      color: var(--color-primary); }
    .account-modal .account-info .user-email {
      font-size: .9rem;
      color: rgba(60, 60, 67, 0.6);
      color: var(--color-secondary); }
  .account-modal button {
    cursor: pointer;
    margin: 1rem 0;
    border-radius: 20px;
    color: #2b2b2b;
    color: var(--color-primary);
    background-color: white;
    background-color: var(--color-system-background-tertiary);
    font-size: 0.9rem;
    padding: 0.2rem 1rem;
    font-weight: 500;
    border: 1px solid rgba(60, 60, 67, 0.12);
    border: 1px solid var(--color-quaternary); }
    .account-modal button:focus {
      outline: none; }

/* Layout master .sass file */
body {
  background-color: #f9f9fc;
  background-color: var(--color-system-background-secondary);
  overflow-x: hidden; }

@media (min-width: 1200px) {
  .container {
    max-width: 1210px; } }

.content-container {
  padding-top: 10rem;
  overflow-x: hidden; }

.alert.alert-danger {
  color: #b10035;
  color: var(--color-vol-red);
  background-color: rgba(177, 0, 53, 0.1);
  background-color: var(--color-vol-red-background);
  border: 0.5px solid rgba(177, 0, 53, 0.1);
  border: 0.5px solid var(--color-vol-red-background);
  border-left: 3px solid #b10035;
  border-left: 3px solid var(--color-vol-red);
  height: 52px; }

.navbar {
  background-color: white;
  background-color: var(--color-system-background-tertiary);
  border-bottom: 0.5px solid rgba(60, 60, 67, 0.3);
  border-bottom: 0.5px solid var(--color-tertiary);
  padding: 0;
  -webkit-user-select: none;
          user-select: none; }
  .navbar .container-fluid {
    padding: 0 1rem; }
  .navbar .navbar-nav {
    flex-flow: row wrap; }
  .navbar a {
    color: #2b2b2b;
    color: var(--color-primary);
    font-weight: 500;
    padding: 0.8rem .5rem;
    margin-right: .5rem; }
    .navbar a.active {
      color: #475ae8;
      color: var(--color-vol-blue);
      border-bottom: 2px solid #475ae8;
      border-bottom: 2px solid var(--color-vol-blue); }
  .navbar .connected-user {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: .9em; }
    .navbar .connected-user .user-name {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-right: .5rem; }
      .navbar .connected-user .user-name .name {
        font-weight: 500; }
      .navbar .connected-user .user-name .position {
        color: rgba(60, 60, 67, 0.6);
        color: var(--color-secondary);
        text-transform: capitalize; }
    .navbar .connected-user .profile-icon {
      width: 2rem;
      color: #475ae8;
      color: var(--color-vol-blue);
      margin-left: .8rem; }
  .navbar.subnav a, .navbar.schedule-subnav a,
  .navbar.subnav span,
  .navbar.schedule-subnav span {
    color: rgba(60, 60, 67, 0.6);
    color: var(--color-secondary);
    font-weight: 400; }
    .navbar.subnav a.active, .navbar.schedule-subnav a.active,
    .navbar.subnav span.active,
    .navbar.schedule-subnav span.active {
      border: 0;
      color: #475ae8;
      color: var(--color-vol-blue);
      font-weight: 500; }
  .navbar.schedule-subnav .navbar-nav {
    flex-flow: row wrap;
    align-items: center;
    padding: .7rem 0; }
    .navbar.schedule-subnav .navbar-nav span {
      padding: 1rem .5rem; }
      .navbar.schedule-subnav .navbar-nav span.custom-check {
        padding: 0; }
    .navbar.schedule-subnav .navbar-nav .week-arrow-navigation {
      background-color: rgba(71, 90, 232, 0.1);
      background-color: var(--color-vol-blue-background);
      content: url(/static/media/icon-arrow-left-primary.a198280e.svg);
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      padding: .5rem;
      margin-right: 1em;
      cursor: pointer; }
      .navbar.schedule-subnav .navbar-nav .week-arrow-navigation.right {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
        margin-left: 1em; }
    .navbar.schedule-subnav .navbar-nav .btn.btn-default {
      padding: .15rem 0.7rem;
      display: block;
      color: #475ae8;
      color: var(--color-vol-blue);
      background-color: rgba(71, 90, 232, 0.1);
      background-color: var(--color-vol-blue-background); }
    .navbar.schedule-subnav .navbar-nav .current-month {
      min-width: 6rem;
      text-align: center;
      text-transform: capitalize;
      cursor: pointer; }
    .navbar.schedule-subnav .navbar-nav ul {
      list-style: none;
      margin: 0;
      align-items: center;
      justify-content: flex-start; }
      .navbar.schedule-subnav .navbar-nav ul li {
        flex-grow: 0;
        width: auto;
        margin-right: 1.25rem; }

.react-datepicker {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; }
  .react-datepicker .react-datepicker__day--selected {
    background-color: #475ae8;
    background-color: var(--color-vol-blue);
    border-radius: 50%; }
  .react-datepicker .react-datepicker__day:hover {
    border-radius: 50%; }
  .react-datepicker .react-datepicker__current-month {
    color: #475ae8;
    color: var(--color-vol-blue); }
  .react-datepicker .react-datepicker__day-names,
  .react-datepicker .react-datepicker__day-name {
    color: rgba(#475ae8, 0.8);
    color: rgba(var(--color-vol-blue), 0.8); }

.schedule {
  display: flex;
  flex-direction: column;
  margin-top: 8rem;
  height: calc(100vh - 7rem); }
  .schedule.has-overview {
    padding-right: 30rem; }
  .schedule .schedule-wrapper {
    width: 100%;
    height: 100%; }
    .schedule .schedule-wrapper .schedule-grid {
      width: 100%;
      height: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      overflow-y: scroll; }
    .schedule .schedule-wrapper .schedule-col-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
      color: rgba(60, 60, 67, 0.3);
      color: var(--color-tertiary);
      width: 100%;
      min-height: 3rem; }
      .schedule .schedule-wrapper .schedule-col-header.current {
        color: #475ae8;
        color: var(--color-vol-blue); }
      .schedule .schedule-wrapper .schedule-col-header .day {
        text-transform: uppercase;
        font-size: 1rem; }
      .schedule .schedule-wrapper .schedule-col-header .date {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.2rem; }
    .schedule .schedule-wrapper .schedule-col {
      margin: 0;
      padding: 0 .5rem;
      display: flex;
      flex-direction: column;
      height: 100%;
      min-width: 15rem;
      width: 100%; }
      .schedule .schedule-wrapper .schedule-col:first-child {
        padding-left: 1rem; }
      .schedule .schedule-wrapper .schedule-col:last-child {
        padding-right: 1rem; }

button.new-task-button {
  position: relative;
  border: none;
  background-color: transparent;
  text-align: left;
  padding: .25rem .5rem .25rem 2.25rem;
  border-radius: 10px;
  color: rgba(60, 60, 67, 0.3);
  color: var(--color-tertiary);
  transition: all .1s ease-in-out; }
  button.new-task-button:hover {
    color: #475AE8;
    background-color: rgba(71, 90, 232, 0.1); }
    button.new-task-button:hover::before {
      opacity: 1;
      background-image: url(/static/media/icon-plus-primary.eb5e4b8a.svg); }
  button.new-task-button:focus {
    outline: 0; }
  button.new-task-button::before {
    content: '';
    opacity: .25;
    background-image: url(/static/media/icon-plus-grey.8679f400.svg);
    width: 1rem;
    height: 1rem;
    position: absolute;
    background-repeat: no-repeat;
    left: 1rem;
    top: .6rem;
    transition: all .1s ease-in-out; }

.background-close-icon, .slide-overview-close, ul.spots button.remove-spot {
  background-image: url(/static/media/icon-close.4a33e8e5.svg);
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center; }

.action-button-with-icon, .slide-overview-controls button.edit, .slide-overview-controls button.copy, .slide-overview-controls button.remove {
  background-repeat: no-repeat;
  background-size: 1rem;
  background-position: 30px center;
  padding: 0.5rem 1.6rem 0.5rem 3.6rem; }

/**
 * Slide overview - global settings
 */
/**
 * Slide overview - Alert at the the top when task has warning
 */
.slide-overview-alert {
  padding: 1rem 2.5rem 0 2.5rem;
  background-image: url(/static/media/icon-warning-red.44d24c52.svg);
  background-repeat: no-repeat;
  background-size: 1.5rem;
  background-position: left center; }
  .slide-overview-alert span {
    color: #ff9500;
    color: var(--color-vol-orange);
    display: inline-block;
    width: 100%; }

.warning-message {
  padding: 1rem 0;
  margin-bottom: 0;
  border-bottom: 0.5px solid rgba(60, 60, 67, 0.3);
  border-bottom: 0.5px solid var(--color-tertiary);
  color: #b10035;
  color: var(--color-vol-red);
  font-weight: 600; }

/**
 * Create new task - dark overlay
 */
.slide-create-new-task-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0rem;
  left: 0rem;
  background-color: rgba(43, 43, 43, 0.85);
  z-index: 1040; }
  .slide-create-new-task-overlay .slide-overview {
    padding: 1.5rem 1rem 1rem 1rem; }
  .slide-create-new-task-overlay.hidden {
    display: none; }

.slide-create-new-task .slide-overview-alert-container {
  border: none;
  justify-content: flex-end; }

.slide-overview-close {
  width: 1.5rem;
  height: 1.5rem;
  background-color: rgba(60, 60, 67, 0.3);
  background-color: var(--color-tertiary);
  border-radius: 50%;
  border: none;
  float: right;
  transition: background-color 0.2s ease-in-out; }
  .slide-overview-close:hover, .slide-overview-close:focus {
    outline: none;
    background-color: #b10035;
    background-color: var(--color-vol-red); }

.slide-overview-head .date {
  text-transform: uppercase;
  color: #475ae8;
  color: var(--color-vol-blue);
  display: block;
  font-weight: 600; }

.slide-overview-head .place {
  color: rgba(60, 60, 67, 0.6);
  color: var(--color-secondary);
  display: block;
  font-size: 1rem;
  margin-bottom: 0.5rem; }

.slide-overview-head .category {
  border-radius: 50px;
  padding: 0 0.6rem;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1.6;
  text-transform: uppercase;
  display: inline-block; }

.slide-overview-desc p {
  color: rgba(60, 60, 67, 0.6);
  color: var(--color-secondary); }

.slide-overview-manager .manager {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start; }
  .slide-overview-manager .manager .manager-image {
    width: 3rem;
    height: 3rem;
    overflow: hidden;
    border-radius: 50%;
    background-color: #475ae8;
    background-color: var(--color-vol-blue);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center; }
    .slide-overview-manager .manager .manager-image span {
      color: white;
      font-size: 1.3rem; }
  .slide-overview-manager .manager .manager-name {
    font-size: 1rem;
    font-weight: 600;
    padding-left: 1rem; }
    .slide-overview-manager .manager .manager-name span {
      display: block;
      color: rgba(60, 60, 67, 0.6);
      color: var(--color-secondary);
      font-size: 1rem;
      font-weight: 400; }

.slide-overview-spots .spots-top-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; }
  .slide-overview-spots .spots-top-bar div.spots {
    background-color: rgba(60, 60, 67, 0.12);
    background-color: var(--color-quaternary);
    padding: 0rem 0.6rem;
    border-radius: 3rem;
    font-weight: 600;
    color: rgba(60, 60, 67, 0.6);
    color: var(--color-secondary);
    position: relative;
    display: flex;
    align-items: center; }
    .slide-overview-spots .spots-top-bar div.spots.full {
      background-color: #4bc88a;
      background-color: var(--color-vol-green);
      color: #fff; }
    .slide-overview-spots .spots-top-bar div.spots svg {
      margin-right: 0.25rem;
      width: 1.25rem; }
  .slide-overview-spots .spots-top-bar .waiting-list {
    color: #288557;
    font-weight: 700;
    padding-left: 0.5rem;
    display: inline-block; }

.slide-overview-spots .waiting-list-user {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.2rem 0 0.5rem 0; }
  .slide-overview-spots .waiting-list-user .waiting-list-position {
    display: flex;
    width: 26px;
    height: 26px;
    background-color: rgba(60, 60, 67, 0.12);
    background-color: var(--color-quaternary);
    color: rgba(60, 60, 67, 0.6);
    color: var(--color-secondary);
    border-radius: 13px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem; }

ul.spots {
  margin: 0.5rem 0rem;
  padding: 0rem;
  max-height: 25vh;
  overflow-y: scroll; }
  ul.spots li.spot {
    padding-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start; }
    ul.spots li.spot input {
      background-color: white;
      background-color: var(--color-system-background);
      color: #2b2b2b;
      color: var(--color-primary); }
      ul.spots li.spot input::-webkit-input-placeholder {
        color: rgba(60, 60, 67, 0.3);
        color: var(--color-tertiary); }
      ul.spots li.spot input::-moz-placeholder {
        color: rgba(60, 60, 67, 0.3);
        color: var(--color-tertiary); }
      ul.spots li.spot input:-ms-input-placeholder {
        color: rgba(60, 60, 67, 0.3);
        color: var(--color-tertiary); }
      ul.spots li.spot input:-moz-placeholder {
        color: rgba(60, 60, 67, 0.3);
        color: var(--color-tertiary); }
    ul.spots li.spot .spot-image {
      width: 2rem;
      height: 2rem;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 0.8rem;
      background-color: rgba(60, 60, 67, 0.12);
      background-color: var(--color-quaternary); }
      ul.spots li.spot .spot-image img {
        height: 100%;
        min-width: 100%; }
  ul.spots button.remove-spot {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    border: none;
    background-color: rgba(60, 60, 67, 0.12);
    background-color: var(--color-quaternary);
    transition: background-color 0.2s ease-in-out; }
    ul.spots button.remove-spot:hover, ul.spots button.remove-spot:focus {
      outline: none;
      background-color: #b10035;
      background-color: var(--color-vol-red); }
  ul.spots input.spot-input {
    flex-grow: 1;
    margin-right: 0.8rem;
    border: none; }
    ul.spots input.spot-input:focus {
      outline: none;
      border-bottom: 0.1rem solid #475ae8;
      border-bottom: 0.1rem solid var(--color-vol-blue); }

.address-remove {
  background-repeat: no-repeat;
  background-size: 1rem;
  background-position: center;
  background-color: rgba(177, 0, 53, 0.1);
  background-color: var(--color-vol-red-background);
  color: #b10035;
  color: var(--color-vol-red);
  background-image: url(/static/media/icon-remove-red.6e2a64e1.svg); }

.slide-overview-controls {
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; }
  .slide-overview-controls button:hover, .slide-overview-controls button:focus {
    outline: none;
    opacity: 0.9; }
  .slide-overview-controls button.edit {
    background-image: url(/static/media/icon-edit-primary.fc51b977.svg); }
  .slide-overview-controls button.copy {
    background-image: url(/static/media/icon-copy-primary.45962c66.svg); }
  .slide-overview-controls button.remove {
    background-color: rgba(177, 0, 53, 0.1);
    background-color: var(--color-vol-red-background);
    color: #b10035;
    color: var(--color-vol-red);
    background-image: url(/static/media/icon-remove-red.6e2a64e1.svg); }

.input-placeholder {
  color: rgba(60, 60, 67, 0.12);
  color: var(--color-quaternary);
  transition: color 0.2s ease-in-out; }

.form-control {
  border-color: inherit;
  box-shadow: none;
  background-color: white;
  background-color: var(--color-system-background-tertiary);
  color: #2b2b2b;
  color: var(--color-primary); }
  .form-control:focus {
    box-shadow: none;
    border-color: rgba(60, 60, 67, 0.3);
    border-color: var(--color-tertiary);
    background-color: white;
    background-color: var(--color-system-background-tertiary);
    color: #2b2b2b;
    color: var(--color-primary); }

.time-input {
  flex-basis: 5rem; }

.flex-datepicker {
  flex-grow: 1;
  margin-right: .5rem; }
  .flex-datepicker .react-datepicker-wrapper {
    display: block; }
    .flex-datepicker .react-datepicker-wrapper .react-datepicker__input-container {
      display: block; }

.flex-timepicker {
  flex-basis: 7rem; }

.full-width-datepicker {
  display: flex;
  flex-direction: column;
  width: 100%; }
  .full-width-datepicker .react-datepicker-wrapper {
    display: block; }
    .full-width-datepicker .react-datepicker-wrapper .react-datepicker__input-container {
      display: block;
      margin-top: .5rem; }

label.edit-label {
  text-transform: uppercase;
  color: rgba(60, 60, 67, 0.6);
  color: var(--color-secondary);
  padding: 0.5rem 0rem 0.2rem 0rem;
  margin: 0rem;
  font-size: .85rem; }
  label.edit-label:nth-of-type(1) {
    padding-top: 0rem; }

label.warning-label {
  color: #b10035;
  color: var(--color-vol-red);
  padding: 0.5rem 0rem 0.2rem 0rem;
  margin: 0rem;
  font-size: 1rem; }
  label.warning-label:nth-of-type(1) {
    padding-top: 0rem; }

input.edit-input {
  border-color: rgba(60, 60, 67, 0.12);
  border-color: var(--color-quaternary); }
  input.edit-input.smaller-width-input {
    flex-basis: 10rem; }

input.edit-input[type="number"] {
  width: 5rem;
  margin: 0 .5rem; }

textarea.edit-input {
  border-color: rgba(60, 60, 67, 0.12);
  border-color: var(--color-quaternary);
  height: 10rem;
  resize: none; }

div.form-check {
  margin-top: 1rem;
  color: #2b2b2b;
  color: var(--color-primary);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 0; }

div.counter-container {
  margin-left: 0.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start; }
  div.counter-container span.counter-btn {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 1.5rem;
    background-color: rgba(60, 60, 67, 0.12);
    background-color: var(--color-quaternary);
    background-repeat: no-repeat;
    background-size: 0.8rem;
    background-position: center;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out; }
    div.counter-container span.counter-btn.subtract {
      background-image: url(/static/media/icon-subtraction.138c10c5.svg); }
    div.counter-container span.counter-btn.add {
      background-image: url(/static/media/icon-addition.1a84d7fe.svg); }
    div.counter-container span.counter-btn:hover, div.counter-container span.counter-btn:focus {
      background-color: #475ae8;
      background-color: var(--color-vol-blue); }

label.counter-label {
  margin: 0rem; }

.spotSearch {
  list-style: none;
  padding: 0;
  margin-bottom: 15px;
  border-radius: 10px;
  background: white;
  background: var(--color-system-background-tertiary); }
  .spotSearch li {
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 10px;
    color: rgba(60, 60, 67, 0.6);
    color: var(--color-secondary); }
    .spotSearch li:hover {
      background: rgba(71, 90, 232, 0.1);
      background: var(--color-vol-blue-background); }

.login-wrapper {
  width: 100%;
  height: 100vh;
  background-color: #c65769;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white; }
  .login-wrapper .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20rem;
    opacity: 0;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
    .login-wrapper .container img {
      width: 80%;
      margin-bottom: 2rem; }
    .login-wrapper .container h1 {
      font-size: 2.3rem; }
    .login-wrapper .container p {
      margin-bottom: 2rem; }
    .login-wrapper .container .btn.btn-default {
      background-color: white;
      color: #c65769;
      display: block;
      width: 80%;
      font-weight: 600;
      font-size: 1.1rem;
      border-radius: 10rem; }

.statistics-container {
  padding-top: 10rem; }
  .statistics-container .Dropdown-root {
    flex-grow: 0;
    width: 8rem; }

.statistics-head {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem; }

.statistics-row {
  margin: 0 -15px 3rem -15px;
  align-items: center;
  justify-content: flex-start; }
  .statistics-row .statistics-box {
    position: relative;
    border-radius: 10px; }
    .statistics-row .statistics-box:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 100%; }
    .statistics-row .statistics-box > .aspect-ratio-content {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    .statistics-row .statistics-box .aspect-ratio-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
    .statistics-row .statistics-box span.number {
      font-size: 4rem;
      font-weight: 600;
      margin: 0;
      text-align: center; }
    .statistics-row .statistics-box span.caption {
      display: inline-block;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1rem;
      text-align: center;
      max-width: 80%; }
    .statistics-row .statistics-box.blue {
      background-color: rgba(71, 90, 232, 0.1);
      color: #475AE8; }
    .statistics-row .statistics-box.purple {
      background-color: rgba(93, 22, 125, 0.1);
      color: #5D167D; }
    .statistics-row .statistics-box.brown {
      background-color: rgba(191, 136, 116, 0.1);
      color: #BF8874; }
    .statistics-row .statistics-box.light-blue {
      background-color: rgba(110, 165, 229, 0.1);
      color: #6EA5E5; }

.statistics-group-row {
  align-items: stretch; }
  .statistics-group-row .group-box {
    background-color: white;
    background-color: var(--color-system-background-tertiary);
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.12);
    border-radius: 0.6rem;
    padding: 1rem;
    margin-bottom: 3rem; }
    .statistics-group-row .group-box h3 {
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 500;
      color: #475AE8;
      width: 100%; }
  .statistics-group-row .group-list {
    padding: 0; }
    .statistics-group-row .group-list li {
      padding: .75rem 0;
      list-style: none;
      border-bottom: 1px solid rgba(60, 60, 67, 0.12);
      border-bottom: 1px solid var(--color-quaternary);
      font-size: .9rem;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .statistics-group-row .group-list li:nth-last-child(1) {
        border-bottom: none; }
      .statistics-group-row .group-list li span {
        display: inline-block;
        font-weight: 600; }

.category-table-header {
  justify-content: space-between; }

table.categories-table {
  table-layout: fixed; }
  table.categories-table td:nth-child(1),
  table.categories-table th:nth-child(1) {
    width: 20%; }
  table.categories-table td:nth-child(2),
  table.categories-table th:nth-child(2) {
    width: 50%; }

table .category-row td {
  vertical-align: middle; }
  table .category-row td.color-cell {
    display: flex;
    flex-direction: row;
    align-items: center; }

table .category-row .btn-wrapper,
table .category-row .edit-input,
table .category-row .btn-delete-wrapper {
  display: none;
  transition: all 0.2s ease-in-out; }

table .category-row.edit-mode {
  background: white;
  background: var(--color-system-background-tertiary);
  border: 1px solid rgba(60, 60, 67, 0.12);
  border: 1px solid var(--color-quaternary); }
  table .category-row.edit-mode .table-action, table .category-row.edit-mode .table-action-edit, table .category-row.edit-mode .table-action-delete,
  table .category-row.edit-mode .category-title {
    display: none; }
  table .category-row.edit-mode .btn-wrapper,
  table .category-row.edit-mode .color-picker,
  table .category-row.edit-mode .edit-input {
    display: block;
    opacity: 1; }

table .category-row.delete-mode {
  background: white;
  background: var(--color-system-background-tertiary);
  border: 1px solid rgba(60, 60, 67, 0.12);
  border: 1px solid var(--color-quaternary); }
  table .category-row.delete-mode .table-action, table .category-row.delete-mode .table-action-edit, table .category-row.delete-mode .table-action-delete {
    display: none; }
  table .category-row.delete-mode .btn-delete-wrapper {
    display: block;
    opacity: 1; }

table .cell-right {
  text-align: right; }

.color-cell-text {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 1rem; }

.color-picker {
  margin: 0 0 0 2rem;
  opacity: 0;
  display: none;
  transition: all 0.2s ease-in-out; }

.not-allowed-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 10rem; }
  .not-allowed-container p {
    font-size: 2.5rem; }

.groups-container {
  padding-top: 10rem;
  padding-bottom: 4rem; }

.groups-weeks {
  margin-bottom: 3rem;
  position: relative; }
  .groups-weeks .title {
    font-size: 1.5rem;
    font-weight: 500;
    color: rgba(60, 60, 67, 0.6);
    color: var(--color-secondary); }
  .groups-weeks .groups-weeks-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    .groups-weeks .groups-weeks-container .month-container {
      margin-top: 1rem;
      flex: 0 1 50%; }
      .groups-weeks .groups-weeks-container .month-container .month-row {
        display: flex;
        margin-bottom: 1rem; }
      .groups-weeks .groups-weeks-container .month-container .month {
        text-transform: uppercase;
        margin-bottom: 0.5rem;
        font-size: 1rem;
        letter-spacing: 0.5px;
        font-weight: 500;
        color: rgba(60, 60, 67, 0.3);
        color: var(--color-tertiary); }

.groups-weeks-menu {
  position: absolute;
  top: 0rem;
  left: -9rem; }
  .groups-weeks-menu ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    .groups-weeks-menu ul li {
      cursor: pointer;
      border-radius: 5px;
      background: rgba(60, 60, 67, 0.12);
      background: var(--color-quaternary);
      color: rgba(60, 60, 67, 0.6);
      color: var(--color-secondary);
      box-shadow: none;
      font-weight: 500;
      font-size: 1.1rem;
      line-height: 1.5rem;
      text-align: center;
      margin-bottom: 0.5rem;
      padding: 0.2rem 2rem; }

.selected, .week-card .week.selected, .groups-weeks-menu ul li.selected {
  background-color: rgba(71, 90, 232, 0.1);
  background-color: var(--color-vol-blue-background);
  color: #475ae8;
  color: var(--color-vol-blue);
  box-shadow: 0 0px 10px rgba(33, 57, 95, 0.08);
  border: .5px solid; }

.groups-stats {
  display: flex;
  flex-direction: column; }
  .groups-stats .group-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.2rem; }
    .groups-stats .group-row .name {
      text-transform: uppercase;
      font-size: 1rem;
      letter-spacing: 0.5px;
      color: rgba(106, 106, 108, 0.6);
      width: 6rem; }
    .groups-stats .group-row .count {
      font-size: 1rem;
      letter-spacing: 0.5px;
      color: #6A6A6C; }

.bar {
  background-color: white;
  background-color: var(--color-system-background-tertiary);
  border-radius: 10px;
  box-shadow: 0 0px 10px rgba(33, 57, 95, 0.08);
  padding: 2rem; }

.manage-users {
  display: flex;
  flex-direction: row;
  justify-content: center; }
  .manage-users .sidebar {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background: white;
    background: var(--color-system-background-tertiary);
    border: 1px solid rgba(60, 60, 67, 0.12);
    border: 1px solid var(--color-quaternary);
    border-radius: 5px;
    padding: 1rem 0rem 1rem 2rem;
    color: rgba(60, 60, 67, 0.6);
    color: var(--color-secondary);
    min-width: 15rem;
    margin-top: calc(2.5rem + 1rem); }
    .manage-users .sidebar .category {
      font-weight: 600;
      display: flex;
      align-items: center;
      margin-bottom: 1.3rem;
      padding: 0.2rem 1rem 0.2rem 0;
      border-right: 5px solid transparent;
      cursor: pointer; }
      .manage-users .sidebar .category .inactive-users {
        margin-left: 0.6rem;
        padding: 0 0.2rem;
        min-width: 22px;
        height: 22px;
        line-height: 22px;
        font-size: 0.8rem;
        font-weight: normal;
        background: #475ae8;
        background: var(--color-vol-blue);
        border-radius: 11px;
        color: #fff;
        text-align: center;
        -webkit-font-feature-settings: "tnum";
                font-feature-settings: "tnum";
        font-variant-numeric: tabular-nums; }
      .manage-users .sidebar .category.active {
        margin-right: 0;
        color: #475ae8;
        color: var(--color-vol-blue);
        padding-right: 1rem;
        border-right: 5px solid #475ae8;
        border-right: 5px solid var(--color-vol-blue); }
    .manage-users .sidebar .dropdown {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-bottom: 1rem; }
      .manage-users .sidebar .dropdown:last-child {
        margin-bottom: 0; }
      .manage-users .sidebar .dropdown .title {
        font-size: 0.9rem;
        color: rgba(60, 60, 67, 0.3);
        color: var(--color-tertiary);
        text-transform: uppercase;
        display: flex;
        font-weight: 500;
        letter-spacing: 1px;
        align-items: center;
        margin-bottom: 0.5rem;
        margin-right: 1rem;
        cursor: pointer; }
        .manage-users .sidebar .dropdown .title span {
          margin-right: 1rem; }
        .manage-users .sidebar .dropdown .title svg {
          width: 0.4rem;
          -webkit-transform: rotate(90deg);
                  transform: rotate(90deg);
          transition: all 0.2s; }
          .manage-users .sidebar .dropdown .title svg.reverse {
            -webkit-transform: rotate(270deg);
                    transform: rotate(270deg); }
      .manage-users .sidebar .dropdown .content {
        flex-direction: column;
        align-items: flex-end;
        display: none; }
        .manage-users .sidebar .dropdown .content.open {
          display: flex; }
        .manage-users .sidebar .dropdown .content span {
          margin-bottom: 0.5rem;
          font-size: 1rem;
          color: rgba(60, 60, 67, 0.6);
          color: var(--color-secondary);
          padding: 0.2rem 1rem 0.2rem 0;
          border-right: 5px solid transparent;
          cursor: pointer; }
          .manage-users .sidebar .dropdown .content span.active {
            color: #475ae8;
            color: var(--color-vol-blue);
            border-right: 5px solid #475ae8;
            border-right: 5px solid var(--color-vol-blue); }
  .manage-users .result-notification {
    display: flex;
    justify-content: flex-end; }
    .manage-users .result-notification p {
      padding: 1rem;
      color: #475ae8;
      color: var(--color-vol-blue);
      background-color: rgba(71, 90, 232, 0.1);
      background-color: var(--color-vol-blue-background);
      border-radius: 12px; }
    .manage-users .result-notification.fail p {
      color: #b10035;
      color: var(--color-vol-red);
      background-color: rgba(177, 0, 53, 0.1);
      background-color: var(--color-vol-red-background); }
  .manage-users .content-main {
    margin-left: 2rem;
    width: 1190px; }
    .manage-users .content-main .select-all-users {
      text-align: center;
      padding: 0.8rem 0;
      background: rgba(71, 90, 232, 0.1);
      background: var(--color-vol-blue-background);
      margin-bottom: 1rem;
      border-radius: 5px;
      font-size: 1rem;
      color: #475ae8;
      color: var(--color-vol-blue); }
      .manage-users .content-main .select-all-users span {
        margin-left: 1rem;
        font-weight: 600;
        cursor: pointer; }
    .manage-users .content-main .topbar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 1rem; }
      .manage-users .content-main .topbar .dropdown .Dropdown-control {
        height: 100%; }
      .manage-users .content-main .topbar .dropdown .Dropdown-menu {
        width: 220px;
        max-height: 205px;
        background-color: white;
        background-color: var(--color-system-background-tertiary);
        border: 1px solid rgba(60, 60, 67, 0.12);
        border: 1px solid var(--color-quaternary);
        border-radius: 4px; }
        .manage-users .content-main .topbar .dropdown .Dropdown-menu .Dropdown-option {
          margin-right: 0; }
      .manage-users .content-main .topbar .dropdown .Dropdown-root {
        height: 100%; }
      .manage-users .content-main .topbar .actions {
        display: flex;
        flex-direction: row; }
        .manage-users .content-main .topbar .actions * {
          margin-right: 0.1rem; }
      .manage-users .content-main .topbar input {
        color: #2b2b2b;
        color: var(--color-primary);
        width: 24rem;
        border-radius: 4px;
        border: 1px solid rgba(60, 60, 67, 0.12);
        border: 1px solid var(--color-quaternary);
        padding: 0 1rem;
        background-color: white;
        background-color: var(--color-system-background-tertiary); }
        .manage-users .content-main .topbar input::-webkit-input-placeholder {
          color: rgba(60, 60, 67, 0.12);
          color: var(--color-quaternary); }
        .manage-users .content-main .topbar input::placeholder {
          color: rgba(60, 60, 67, 0.12);
          color: var(--color-quaternary); }
        .manage-users .content-main .topbar input:focus {
          outline: none; }
    .manage-users .content-main .table-users {
      width: 100%;
      padding: 0 1rem;
      margin-bottom: 3rem; }
      .manage-users .content-main .table-users .custom-check-wrapper {
        padding: 0 0.3rem; }
        .manage-users .content-main .table-users .custom-check-wrapper .custom-check {
          width: 1.3rem;
          height: 1.3rem;
          border: 1px solid rgba(60, 60, 67, 0.3);
          border: 1px solid var(--color-tertiary); }
        .manage-users .content-main .table-users .custom-check-wrapper.checked .custom-check {
          border-color: #475ae8;
          border-color: var(--color-vol-blue);
          background-size: 0.8rem;
          background-color: rgba(71, 90, 232, 0.1);
          background-color: var(--color-vol-blue-background);
          background-image: url(/static/media/icon-check-blue.6b25e2c6.svg); }
      .manage-users .content-main .table-users thead th:first-child {
        width: 3rem; }
      .manage-users .content-main .table-users thead th:last-child {
        text-align: right; }
      .manage-users .content-main .table-users thead th .table-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer; }
        .manage-users .content-main .table-users thead th .table-title span {
          margin-right: 0.8rem; }
        .manage-users .content-main .table-users thead th .table-title div {
          display: flex;
          flex-direction: column; }
          .manage-users .content-main .table-users thead th .table-title div .up {
            margin-bottom: 0.2rem; }
          .manage-users .content-main .table-users thead th .table-title div .down {
            -webkit-transform: rotate(180deg);
                    transform: rotate(180deg); }
          .manage-users .content-main .table-users thead th .table-title div .up,
          .manage-users .content-main .table-users thead th .table-title div .down {
            color: rgba(60, 60, 67, 0.3);
            color: var(--color-tertiary); }
            .manage-users .content-main .table-users thead th .table-title div .up.active,
            .manage-users .content-main .table-users thead th .table-title div .down.active {
              color: #475ae8;
              color: var(--color-vol-blue); }
            .manage-users .content-main .table-users thead th .table-title div .up.hide,
            .manage-users .content-main .table-users thead th .table-title div .down.hide {
              display: none; }
      .manage-users .content-main .table-users tbody tr td .edit,
      .manage-users .content-main .table-users tbody tr td .delete {
        cursor: pointer;
        font-weight: 500;
        font-size: 0.8rem; }
      .manage-users .content-main .table-users tbody tr td:last-child {
        text-align: right; }
      .manage-users .content-main .table-users tbody tr td .category {
        margin-left: .6rem;
        background: rgba(60, 60, 67, 0.12);
        background: var(--color-quaternary);
        color: rgba(60, 60, 67, 0.6);
        color: var(--color-secondary);
        text-transform: uppercase;
        font-size: .7rem;
        font-weight: 600;
        padding: .3rem .4rem;
        border-radius: 5px;
        vertical-align: middle; }

@page {
  size: auto;
  margin: 10mm; }

@media print {
  .day-card {
    page-break-inside: avoid;
    -webkit-column-break-inside: avoid;
            break-inside: avoid;
    page-break-inside: avoid; } }

.print {
  /*
    * Utility classes used in the print
    */ }
  .print .caption {
    color: rgba(60, 60, 67, 0.6);
    text-transform: uppercase;
    letter-spacing: 1px; }
  .print .print-description {
    padding: 0;
    margin: 0; }
  .print .flex-y-align {
    display: flex;
    align-items: center; }
  .print .category-pill {
    display: inline-block;
    width: 3rem;
    height: 0.7rem;
    margin-right: 1rem;
    border-radius: 0.5rem;
    background-color: wheat; }
    .print .category-pill.vertical {
      width: 0.7rem;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0.5rem; }
  .print .print-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .print .table-users {
    width: 100%;
    padding: 0 1rem;
    margin-bottom: 3rem; }
    .print .table-users .custom-check-wrapper {
      padding: 0 0.3rem; }
      .print .table-users .custom-check-wrapper .custom-check {
        width: 1.3rem;
        height: 1.3rem;
        border: 1px solid rgba(60, 60, 67, 0.3);
        border: 1px solid var(--color-tertiary); }
      .print .table-users .custom-check-wrapper.checked .custom-check {
        border-color: #475ae8;
        border-color: var(--color-vol-blue);
        background-size: 0.8rem;
        background-color: rgba(71, 90, 232, 0.1);
        background-color: var(--color-vol-blue-background);
        background-image: url(/static/media/icon-check-blue.6b25e2c6.svg); }
    .print .table-users thead th:first-child {
      width: 3rem; }
    .print .table-users thead th:last-child {
      text-align: right; }
    .print .table-users thead th .table-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer; }
      .print .table-users thead th .table-title span {
        margin-right: 0.8rem; }
      .print .table-users thead th .table-title div {
        display: flex;
        flex-direction: column; }
        .print .table-users thead th .table-title div .up {
          margin-bottom: 0.2rem; }
        .print .table-users thead th .table-title div .down {
          -webkit-transform: rotate(180deg);
                  transform: rotate(180deg); }
        .print .table-users thead th .table-title div .up,
        .print .table-users thead th .table-title div .down {
          color: rgba(60, 60, 67, 0.3);
          color: var(--color-tertiary); }
          .print .table-users thead th .table-title div .up.active,
          .print .table-users thead th .table-title div .down.active {
            color: #475ae8;
            color: var(--color-vol-blue); }
          .print .table-users thead th .table-title div .up.hide,
          .print .table-users thead th .table-title div .down.hide {
            display: none; }
    .print .table-users tbody tr td .edit,
    .print .table-users tbody tr td .delete {
      cursor: pointer;
      font-weight: 500;
      font-size: 0.8rem; }
    .print .table-users tbody tr td:last-child {
      text-align: right; }
    .print .table-users tbody tr td .category {
      margin-left: .6rem;
      background: rgba(60, 60, 67, 0.12);
      background: var(--color-quaternary);
      color: rgba(60, 60, 67, 0.6);
      color: var(--color-secondary);
      text-transform: uppercase;
      font-size: .7rem;
      font-weight: 600;
      padding: .3rem .4rem;
      border-radius: 5px;
      vertical-align: middle; }

.print .header {
  padding-bottom: 2rem;
  border-bottom: 0.5px solid rgba(60, 60, 67, 0.12);
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }
  .print .header img {
    max-width: 12rem; }
  .print .header .title {
    font-size: 2rem;
    font-weight: 600; }
  .print .header .subtitle {
    font-size: 1.25rem;
    font-weight: 500;
    color: #475ae8;
    color: var(--color-vol-blue); }
  .print .header .print-date {
    color: rgba(60, 60, 67, 0.6);
    text-align: right; }

.print .day-card {
  border-bottom: 1px solid rgba(60, 60, 67, 0.12);
  padding: 2.5rem 0;
  width: 100%; }
  .print .day-card h2 {
    margin-bottom: 0.5rem;
    text-transform: capitalize; }
  .print .day-card .pill, .print .day-card .task .task-details .waiting-list, .task .task-details .print .day-card .waiting-list, .print .day-card .task .task-details .category, .task .task-details .print .day-card .category, .print .day-card .spots {
    border-radius: 50px;
    font-size: 0.8rem;
    padding: 0 0.4rem;
    display: inline-block; }
  .print .day-card .spots {
    background-color: rgba(60, 60, 67, 0.12);
    background-color: var(--color-quaternary);
    color: rgba(60, 60, 67, 0.6);
    color: var(--color-secondary);
    font-weight: 700;
    z-index: 2;
    margin-left: 0.5rem; }
    .print .day-card .spots.full {
      background-color: #4bc88a;
      background-color: var(--color-vol-green);
      color: #fff; }
    .print .day-card .spots svg {
      margin-right: 4px;
      margin-bottom: 3px; }
  .print .day-card .event {
    width: 48%;
    position: relative;
    padding-left: 1.5rem;
    margin-bottom: 1rem; }
    .print .day-card .event .event-title {
      font-size: 1.25rem; }
    .print .day-card .event .event-header {
      display: block;
      margin-bottom: 0.5rem; }

