.select-user-modal {
	width: 100%;
	height: 100%;
	background-color: rgba($color: $color-black, $alpha: 0.8);
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1030;
}

.modal-container {
	height: 80vh;
	max-height: 800px;
	width: 100%;
	max-width: 1200px;
	background-color: $color-light-grey;
	overflow: hidden;
	border-radius: 0.6rem;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-start;
	.row {
		flex-grow: 1;
		align-items: stretch;
		max-height: calc(100% - 6.5rem);
		overflow: hidden;
		.col-8, .col-4 {
			height: 100%;
		}
	}
}

.modal-head {
	border-bottom: .5px solid $color-grey;
	padding: 0.8rem 1rem;
	h2 {
		margin: 0px;
		font-size: 1.1rem;
		font-weight: 600;
	}
}

.modal-col-wrapper {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-start;
	height: 100%;
	.container-fluid {
		flex-grow: 1;
		background-color: #fff;
		border-right: .5px solid $color-grey;
		overflow: hidden;
		.row {
			height: 100%;
			max-height: calc(80vh - 10.7rem);
			.col-4, .col-8 {
				padding-right: 0;
			}
		}
	}
}

.modal-search {
	width: 100%;
	padding: 0.8rem 1rem;
	border-bottom: .5px solid $color-grey;
	border-right: .5px solid $color-grey;
	background-color: #fff;
	input.modal-search-input {
		height: 2.5rem;
		font-size: 1rem;
		border-width: .5px;
		border-color: $color-light-grey;
		&::-webkit-input-placeholder {
			color: $color-grey;
		}
		&::-moz-placeholder {
			color: $color-grey;
		}
		&:-ms-input-placeholder {
			color: $color-grey;
		}
		&:-moz-placeholder {
			color: $color-grey;
		}
	}
}

.modal-col-row {
	flex-grow: 1;
	background-color: #fff;
	border-right: .5px solid $color-grey;
}

.modal-user-filter {
	border-right: .5px solid $color-grey;
	padding: 0;
	max-height: calc(80vh - 10.7rem);
	overflow-y: scroll;
}

ul.filter-list {
	margin: 0;
	padding: 0.8rem 0;
	max-height: 100%;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;

	li.filter {
		list-style: none;
		padding: 0.5rem 0;
		&:nth-child(1) {
			padding-top: 0;
		}
		span {
			color: $color-dark-grey;
			transition: color 0.2s ease-in-out;
			padding: .3rem 1rem;
			font-size: 1rem;
			cursor: pointer;
			&:hover {
				text-decoration: none;
				font-weight: 600;
			}
		}
		&.current {
			span {
				font-weight: 600;
				color: $theme-blue;
				border-left: 5px solid;
				padding: .3rem calc(1rem - 5px);
			}
		}
	}
}

.modal-user-list {
	overflow-y: scroll;
	max-height: calc(80vh - 10.7rem);
}

ul.user-list {
	padding: 0.8rem 1rem 0 0;
	margin: 0;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;

	li.user {
		list-style: none;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 1rem;
		&.spot {
			margin-bottom: 0;
		}
		.user-image {
			width: 2rem;
			height: 2rem;
			border-radius: 2rem;
			overflow: hidden;
			background-color: get-color(vol-blue-background);
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			margin-bottom: 0;
			
			span,
			svg {
				color: get-color(vol-blue);
				font-size: 0.8rem;
			}

			svg {
				width: 1rem;
				margin: 0;
			}
		}
		.user-name {
			margin-left: 0.8rem;
			flex-grow: 1;
		}
		.user-task {
			height: 1.6rem;
			border-radius: 1.6rem;
			line-height: 1.6rem;
			text-align: center;
			font-size: 0.9rem;
			color: $color-dark-grey;
			margin-right: 1rem;
			&.active {
				color: $color-black;
			}
		}
		.user-label {
			display: inline-block;
			padding: 0.1rem;
			width: 6.5rem;
			text-align: center;
			font-size: 0.9rem;
			border-radius: 0.4rem;
			background-color: $color-light-grey;
			color: $color-dark-grey;
			text-transform: uppercase;
			&.active {
				background-color: rgba($color: $theme-green, $alpha: 0.2);
				color: $theme-green;
			}
		}
		.user-check {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			margin-bottom: 0rem;
			margin-right: 0rem;
			margin-left: 10px;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			input {
				width: 100%;
				height: 100%;
				opacity: 0;
				position: absolute;
				cursor: pointer;
			}
			.custom-check {
				display: block;
				width: 1.5rem;
				height: 1.5rem;
				background-color: $color-light-grey;
				border-radius: 0.2rem;
				border: 0.1rem solid $color-grey;
				cursor: pointer;
			}
			&.checked {
				.custom-check {
					background-color: rgba($color: $theme-blue, $alpha: 0.2);
					border-color: transparent;
					background-image: url('../assets/svg/icon-check-primary.svg');
					background-repeat: no-repeat;
					background-size: 1rem;
					background-position: center; 
				}
			}
		}
	}
}

.modal-selected-user {
	padding: 0.8rem 1rem 0.8rem 0rem;
	margin-left: -15px;
	height: 100%;
	height: calc(80vh - 6.5rem);
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	h3 {
		color: $color-dark-grey;
		font-size: 0.9rem;
		font-weight: 600;
		text-transform: uppercase;
		margin-bottom: 0rem;
	}
}

.modal-description {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	min-height: 40%;
	label {
		color: $color-dark-grey;
		font-size: 0.9rem;
		font-weight: 600;
		text-transform: uppercase;
		margin-bottom: 0.25rem;
	}
	textarea.modal-textarea {
		width: 100%;
		resize: none;
		height: 6rem;
		font-size: 1rem;
		border-width: .5px;
		border-color: $color-grey;
		&::-webkit-input-placeholder {
			color: $color-grey;
		}
		&::-moz-placeholder {
			color: $color-grey;
		}
		&:-ms-input-placeholder {
			color: $color-grey;
		}
		&:-moz-placeholder {
			color: $color-grey;
		}
	}
	.Dropdown-menu {
		position: static;
	}
	.dropdown {
		button.dropdown-toggle {
			background-color: #fff;
			border: .5px solid $color-grey;
			height: 2.7rem;
			line-height: 2.6rem;
			padding: 0 1rem;
			margin: 0;
			border-radius: 0.4rem;
			text-align: left;
			color: $color-grey;
			&:after {
				width: 1.5rem;
				height: 2.7rem;
				float: right;
				margin: 0;
				background-image: url('../assets/svg/icon-arrow-down-grey.svg');
				background-repeat: no-repeat;
				background-size: 1rem;
				background-position: center;
				border: none;
			}
			&:hover, &:focus {
				outline: none;
			}
		}
	}
}

.modal-footer {
	background-color: #fff;
	border-top: .5px solid $color-grey;
	padding: .5rem 1rem;
	height: 3.5rem;
	button.footer-btn {
		background-color: $color-light-grey;
		border: .5px solid $color-grey;
		border-radius: 0.2rem;
		height: 2rem;
		padding: 0rem 1rem;
		font-size: 0.9rem;
		transition: background-color 0.2s ease-in-out;
		&.cancel {
			&:hover, &:focus {
				outline: none;
				background-color: $color-grey;
			}
		}
		&.save {
			background-color: $theme-green;
			border-color: #47B57E;
			color: #fff;
			&:hover, &:focus {
				outline: none;
				background-color: #47B57E;
			}
		}
	}
}

.select-all {
	background-color: #EFEFEF;
    border: 0.5px solid #DDDDDD;
    border-radius: 0.2rem;
    height: 2rem;
    padding: 0rem 1rem;
    font-size: 0.9rem;
	transition: background-color 0.2s ease-in-out;
	margin-top: 10px;
	margin-right: 30px;
	float: right;
}

.select-all-clear {
	clear: both;
}