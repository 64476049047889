.not-allowed-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 10rem;

    p {
        font-size: 2.5rem;
    }
}