.user-table-header {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	.user-dropdown-wrapper {
		margin-right: 0.8rem;
	}
}

.btn.btn-primary.btn-table {
	
	&.add-user {
		background-image: url('../assets/svg/icon-plus-primary.svg');
		background-repeat: no-repeat;
		background-size: 1rem;
		background-position: 0.8rem center;
		padding-left: 2.25rem;
		margin-left: 1rem;
	}
	
	&:hover {
		background-color: rgba($color: $theme-blue, $alpha: 0.2);
	}
}

input.user-table-search {
	margin-left: auto;
	height: 2.5rem;
	width: 30%;
	line-height: 2.5rem;
	font-size: 1rem;
	border-width: .5px;
	border-color: $color-light-grey;

	&::placeholder {
		color: $system-grey-3;
	}
}

.user-table-container {
	width: 100%;
	overflow-x: scroll;
}

.table.user-table {
	margin-top: 0.8rem;
	color: $color-black;
	width: 100%;
	
	* {
		border-color: rgba($color: $theme-blue, $alpha: 0.1);
	}
	
	thead th {
		font-size: 1rem;
		border-width: 1px;
		white-space: nowrap;
		
		&:nth-last-child(1) {
			text-align: right;
		}
		
		span {
			display: inline-block;
			padding-right: 1rem;
			cursor: pointer;
		}

		p {
			cursor: pointer;
			display: inline-block;
			margin-bottom: 0px;
		}

		p img {
			width: 7px;
		}
	}
	
	tbody {
		tr:last-child {
			border-bottom-width: 1px;
			border-bottom-style: solid;
		}
	}
}

.user-table-row {
	td {
		font-size: .9rem;
		padding-top: 0.6rem;
		padding-bottom: 0.6rem;
		vertical-align: middle;
		transition: all 0.2s ease-in-out;
		white-space: nowrap;
		&:nth-last-child(1) {
			text-align: right;
			height: 2.5rem;
		}
	}

	.userlevel {
		width: 100%;
		display: inline-flex;
		flex-direction: column;
		align-items: flex-start;

		.category-title {
			background: $color-light-grey;
			color: #a3a3a3;
			max-width: auto;
			text-transform: uppercase;
			font-size: 0.7rem;
			font-weight: 600;
			padding: 0.1rem 0.3rem;
			text-align: center;
			border-radius: 5px;
		}
	}
	
	.user-group {
		display: inline-block;
		height: 2rem;
		padding: 0 0.5rem;
		border-radius: 2rem;
		line-height: 2rem;
		text-align: center;
		background-color: $color-light-grey;
	}
	
	.user-task {
		display: inline-block;
		width: 2rem;
		height: 2rem;
		border-radius: 2rem;
		line-height: 2rem;
		text-align: center;
		font-size: 1.1rem;
		
		&.total {
			margin-right: 0.8rem;
		}
		
		&.purple {
			background-color: rgba($color: $theme-purple, $alpha: 0.2);
			color: $theme-purple;
			transform: scale(0.8);
		}
		
		&.brown {
			background-color: rgba($color: $theme-brown, $alpha: 0.2);
			color: $theme-brown;
			transform: scale(0.8);
		}
		
		&.light-blue {
			background-color: rgba($color: $theme-light-blue, $alpha: 0.2);
			color: $theme-light-blue;
			transform: scale(0.8);
		}
	}

	.user-edit-btn {
		background: transparent;
		border: none;
		text-decoration: underline;
		color: $theme-blue;
		font-size: 0.9rem;
		transition: opacity 0.2s ease-in-out;
		padding: 0;
		
		&:hover, &:focus {
			outline: none;
			opacity: 0.8;
		}
	}

	//Hiding input and buttons in original mode
	.user-group-input, .btn-wrapper {
		display: none;
	}

	//Just making the checkbox a bit smaller
	.custom-check-wrapper {
		.custom-check {
				width: 1.2rem;
				height: 1.2rem;
				margin-right: 0rem;
				margin-top: 0.4rem;
		}

		&.checked {
			.custom-check {
				background-size: 0.8rem;
			}
		}
	}

	&.mode-edit {
		background-color: #fff;
		border: 1px solid $color-light-grey;
		
		td {
			&:nth-last-child(1) {
				height: 8rem;
				vertical-align: bottom;
			}
		}
		
		.user-group-input,
		.btn-wrapper {
			display: flex;
			justify-content: space-around;
		}

		.user-group-input {
			width: 6rem;
			border-width: 1px;
			padding-left: 0.5rem;
		}

		//Hiding user group and edit button in edit mode
		.user-group, .user-edit-btn {
			display: none;
		}
	}
}