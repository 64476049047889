.sidebar {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background: get-color(system-background-tertiary);
    border: 1px solid get-color(quaternary);
    border-radius: 5px;
    padding: 1rem 0rem 1rem 2rem;
    color: get-color(secondary);
    min-width: 15rem;
    margin-top: calc(2.5rem + 1rem);

    .category {
        font-weight: 600;
        display: flex;
        align-items: center;
        margin-bottom: 1.3rem;
        padding: 0.2rem 1rem 0.2rem 0;
        border-right: 5px solid transparent;
        cursor: pointer;

        .inactive-users {
            margin-left: 0.6rem;
            padding: 0 0.2rem;
            min-width: 22px;
            height: 22px;
            line-height: 22px;
            font-size: 0.8rem;
            font-weight: normal;
            background: get-color(vol-blue);
            border-radius: 11px;
            color: $white;
            text-align: center;
            font-variant-numeric: tabular-nums;
        }

        &.active {
            margin-right: 0;
            color: get-color(vol-blue);
            padding-right: 1rem;
            border-right: 5px solid get-color(vol-blue);
        }
    }

    .dropdown {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }

        .title {
            font-size: 0.9rem;
            color: get-color(tertiary);
            text-transform: uppercase;
            display: flex;
            font-weight: 500;
            letter-spacing: 1px;
            align-items: center;
            margin-bottom: 0.5rem;
            margin-right: 1rem;
            cursor: pointer;

            span {
                margin-right: 1rem;
            }

            svg {
                width: 0.4rem;
                transform: rotate(90deg);
                transition: all 0.2s;

                &.reverse {
                    transform: rotate(270deg);
                }
            }
        }

        .content {
            flex-direction: column;
            align-items: flex-end;
            display: none;

            &.open {
                display: flex;
            }

            span {
                margin-bottom: 0.5rem;
                font-size: 1rem;
                color: get-color(secondary);
                padding: 0.2rem 1rem 0.2rem 0;
                border-right: 5px solid transparent;
                cursor: pointer;

                &.active {
                    color: get-color(vol-blue);
                    border-right: 5px solid get-color(vol-blue);
                }
            }
        }
    }
}
