body {
    background-color: get-color(system-background-secondary);
    overflow-x: hidden;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1210px;
    }
}

.content-container {
    padding-top: 10rem;
    overflow-x: hidden;
}

.alert {
    &.alert-danger {
        color: get-color(vol-red);
        background-color: get-color(vol-red-background);
        border: 0.5px solid get-color(vol-red-background);
        border-left: 3px solid get-color(vol-red);
        height: 52px;
    }
}