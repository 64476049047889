.loading-container {
    height: 100vh;
    width: 100%;
    display: flex;
    background: get-color(system-background-secondary);
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .spinner {
        margin-top: 2rem;
        width: 90px;
    }
}

.loader-container {
    height: calc(100vh - 250px); // Minus the header
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 90px;
    }
}