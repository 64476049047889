button.btn {
    border: 1px solid transparent;
    padding: 0.5rem 2.75rem;
    border-radius: 5px;
    transition: all ease-in-out .15s;

    &:hover {
        border-color: transparent;
    }

    &.btn-primary {
        background-color: get-color(vol-blue-background);
        color: get-color(vol-blue);
    }

    &.btn-primary-inverted {
        background-color: get-color(vol-blue);
        color: white;
    }

    &.btn-cancel {
        background-color: get-color(system-background-tertiary);
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
        border: 1px solid get-color(tertiary);
        color: get-color(primary);
    }

    &.btn-save {
        background-color: get-color(vol-green);
        color: white;
    }

    &.btn-delete {
        background-color: get-color(vol-red-background);
        color: get-color(vol-red);

        &:hover,
        &:focus {
            background-color: get-color(vol-red);
            color: white;
        }
    }

    &.btn-table {
        padding: 0.5rem 1rem;
    }

    &.btn-table-small {
        padding: 0.1rem 1rem;
        margin-left: 0.5rem;
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }

    &.btn-inline {
        padding: 0 0.5rem;
        font-size: 1.5rem;
        line-height: 1.5;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: 0.5rem;
        min-width: 2.5rem;

        span {
            font-size: 1.5rem;
            margin: 0 0.5rem 0 0;
        }

        &.add {
            background: get-color(vol-blue-background);
            color: get-color(vol-blue);
            font-size: 1.9rem;
            span {
                margin: 0;
            }
        }

        &.confirm {
            background: get-color(vol-green-background);
            color: get-color(vol-green);
            font-size: 1rem;
        }
    }

    &.btn-toggle {
        color: get-color(secondary);
        background-color: get-color(quaternary);
        border: .5px solid get-color(tertiary);
        padding: 1rem 2rem;
        text-align: left;
        font-weight: 600;
        width: 49%;

        &.active {
            color: get-color(vol-blue);
            background-color: get-color(vol-blue-background);
            border-color: get-color(vol-blue);
        }

        svg {
            width: 25px;
        }
    }
}