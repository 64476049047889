.week-card {
    background-color: get-color(system-background-tertiary);
    border-radius: 10px;
    box-shadow: 0 0px 10px rgba(33, 57, 95, 0.08);
    padding: .75rem;
    width: 5rem;
    margin-right: 1rem;
    position: relative;
    cursor: pointer;

    .week, .group {
        border-radius: 5px;
        font-size: 1rem;
        height: 1.5rem;
        line-height: 1.5rem;
    }

    .week {
        background: get-color(system-background-tertiary);
        text-align: center;
        color: get-color(secondary);
        margin-bottom: 0.5rem;
        font-weight: 500;

        &.selected {
            @extend .selected;
        }
    }

    .group {
        background: get-color(vol-green-background);
        text-align: center;
        color: $theme-green;
        font-weight: 500;

        &.warning {
            background: rgba($theme-orange, 0.2);
        }

        p {
            margin: 0;
            padding: 0;
            line-height: 1.3rem;
            height: 1rem;
        }

        p img{
            color: get-color(vol-orange);
            width: 100%;
            height: 100%;
        }
    }

    .select-group {
        margin: 0;
        padding:  0 .75rem .4rem .75rem;
        position: absolute;
        top: 72px;
        left: 0;
        right: 0;
        background: get-color(system-background-tertiary);
        z-index: 1000;
        list-style: none;
        border-radius: 0px 0px 10px 10px;
        box-shadow: 0 6px 10px rgba(33, 57, 95, 0.08);
        transition: all .3s;
        max-height: 400px;

        .group {
            background-color: get-color(vol-blue-background);
            color: get-color(vol-blue);
            margin-bottom: 0.26rem;
            opacity: 1;
            visibility: visible;
            transition-property: opacity, background, color;
            transition-duration: .3s, .2s, .1s;

            &:hover {
                background: rgba($theme-green, 0.2);
                color: $theme-green;
            }

            &.trash {
                background: rgba($theme-red, 0.15);

                img {
                    height: 19px;
                    padding-bottom: 5px;
                }

                &:hover {
                    background: rgba($theme-red, 0.1);
                }
            }
        }

        &.hidden {
            z-index: 0;
            max-height: 0px;
            box-shadow: none;

            .group {
                visibility: hidden;
                opacity: 0;
            }
        }
    }
}