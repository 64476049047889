.account-modal {
    position: absolute;
    right: 1rem;
    width: 320px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    background: get-color(system-background-tertiary);
    border: 1px solid get-color(quaternary);
    top: calc(56px + 1rem);
    z-index: 10;
    display: flex;
    align-items: center;
    flex-direction: column;

    .account-info {
        width: 100%;
        padding: 1rem 0;
        border-bottom: 1px solid get-color(quaternary);
        display: flex;
        align-items: center;
        flex-direction: column;

        .user-name {
            font-weight: 600;
            font-size: 1rem;
            color: get-color(primary);
        }

        .user-email {
            font-size: .9rem;
            color: get-color(secondary);
        }
    }

    button {
        cursor: pointer;
        margin: 1rem 0;
        border-radius: 20px;
        color: get-color(primary);
        background-color: get-color(system-background-tertiary);
        font-size: 0.9rem;
        padding: 0.2rem 1rem;
        font-weight: 500;
        border: 1px solid get-color(quaternary);

        &:focus {
            outline: none;
        }
    }
}