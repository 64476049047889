.schedule {
    display: flex;
    flex-direction: column;
    margin-top: 8rem;
    height: calc(100vh - 7rem);

    &.has-overview {
        padding-right: 30rem; // Width of the overview
    }

    .schedule-wrapper {

        width: 100%;
        height: 100%;
        // background-color: aqua;

        .schedule-grid {
            width: 100%;
            height: 100%;
            display: flex;
            flex-flow: row nowrap;
            align-items: stretch;
	        justify-content: flex-start;
            overflow-y: scroll;
        }

        .schedule-col-header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 1rem;
            color: get-color(tertiary); 
            width: 100%;
            min-height: 3rem;
            
            &.current {
                color: get-color(vol-blue);
            }

            .day {
                text-transform: uppercase;
                font-size: 1rem;
            }

            .date {
                font-size: 1.5rem;
                font-weight: 500;
                line-height: 1.2rem;
            }
        }

        .schedule-col {
            margin: 0;
            padding: 0 .5rem;
            display: flex;
            flex-direction: column;
            height: 100%;
            min-width: 15rem;
            width: 100%;

            &:first-child {
                padding-left: 1rem;
            }
            &:last-child {
                padding-right: 1rem;
            }
        }
    }

}

button.new-task-button {
    position: relative;
    border: none;
    background-color: transparent;
    text-align: left;
    padding: .25rem .5rem .25rem 2.25rem;
    border-radius: 10px;
    color: get-color(tertiary);
    transition: all .1s ease-in-out;

    &:hover {
        color: $color-primary;
        background-color: rgba($color-primary, .1);
        &::before {
            opacity: 1;
            background-image: url('../assets/svg/icon-plus-primary.svg');
        }
    }

    &:focus {
        outline: 0;
    }

    &::before {
        content: '';
        opacity: .25;
        background-image: url('../assets/svg/icon-plus-grey.svg');
        width: 1rem;
        height: 1rem;
        position: absolute;
        background-repeat: no-repeat;
        left: 1rem;
        top: .6rem;
        transition: all .1s ease-in-out;
    }
}