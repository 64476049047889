.topbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;

    .dropdown {
        .Dropdown-control {
            height: 100%;
        }

        .Dropdown-menu {
            width: 220px;
            max-height: 205px;
            background-color: get-color(system-background-tertiary);
            border: 1px solid get-color(quaternary);
            border-radius: 4px;

            .Dropdown-option {
                margin-right: 0;
            }
        }

        .Dropdown-root {
            height: 100%;
        }
    }

    .actions {
        display: flex;
        flex-direction: row;

        * {
            margin-right: 0.1rem;
        }
    }

    input {
        color: get-color(primary);
        width: 24rem;
        border-radius: 4px;
        border: 1px solid get-color(quaternary);
        padding: 0 1rem;
        background-color: get-color(system-background-tertiary);

        &::placeholder {
            color: get-color(quaternary);
        }

        &:focus {
            outline: none;
        }
    }
}