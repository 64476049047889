// MARK: Abstract classes
// ----------------------

.background-close-icon {
	background-image: url("../assets/svg/icon-close.svg");
	background-repeat: no-repeat;
	background-size: 12px;
	background-position: center;
}

.action-button-with-icon {
	background-repeat: no-repeat;
	background-size: 1rem;
	background-position: 30px center;
	padding: 0.5rem 1.6rem 0.5rem 3.6rem;
}

// MARK: Overview styles
// ---------------------

/**
 * Slide overview - global settings
 */

/**
 * Slide overview - Alert at the the top when task has warning
 */

.slide-overview-alert {
	padding: 1rem 2.5rem 0 2.5rem;
	background-image: url("../assets/svg/icon-warning-red.svg");
	background-repeat: no-repeat;
	background-size: 1.5rem;
	background-position: left center;

	span {
		color: get-color(vol-orange);
		display: inline-block;
		width: 100%;
	}
}

.warning-message {
	padding: 1rem 0;
	margin-bottom: 0;
	border-bottom: 0.5px solid get-color(tertiary);
	color: get-color(vol-red);
	font-weight: 600;
}

/**
 * Create new task - dark overlay
 */
.slide-create-new-task-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0rem;
	left: 0rem;
	background-color: rgba($color: get-light-color(primary), $alpha: 0.85);
	z-index: 1040; // Menu in position-fixed is 1030.

	.slide-overview {
		padding: 1.5rem 1rem 1rem 1rem;
	}

	&.hidden {
		display: none;
	}
}

.slide-create-new-task {
	.slide-overview-alert-container {
		border: none;
		justify-content: flex-end;
	}
}

.slide-overview-close {
	@extend .background-close-icon;
	width: 1.5rem;
	height: 1.5rem;
	background-color: get-color(tertiary);
	border-radius: 50%;
	border: none;
	float: right;
	transition: background-color 0.2s ease-in-out;

	&:hover,
	&:focus {
		outline: none;
		background-color: get-color(vol-red);
	}
}

.slide-overview-head {
	@extend .slide-overview-section;

	.date {
		text-transform: uppercase;
		color: get-color(vol-blue);
		display: block;
		font-weight: 600;
	}

	.place {
		color: get-color(secondary);
		display: block;
		font-size: 1rem;
		margin-bottom: 0.5rem;
	}

	.category {
		border-radius: 50px;
		padding: 0 0.6rem;
		font-weight: 500;
		font-size: 0.8rem;
		line-height: 1.6;
		text-transform: uppercase;
		display: inline-block;
	}
}

.slide-overview-desc {
	@extend .slide-overview-section;

	p {
		color: get-color(secondary);
	}
}

.slide-overview-manager {
	@extend .slide-overview-section;

	.manager {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;

		.manager-image {
			width: 3rem;
			height: 3rem;
			overflow: hidden;
			border-radius: 50%;
			background-color: get-color(vol-blue);
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;

			span {
				color: white;
				font-size: 1.3rem;
			}
		}

		.manager-name {
			font-size: 1rem;
			font-weight: 600;
			padding-left: 1rem;

			span {
				display: block;
				color: get-color(secondary);
				font-size: 1rem;
				font-weight: 400;
			}
		}
	}
}

.slide-overview-spots {
	@extend .slide-overview-section;

	.spots-top-bar {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		div.spots {
			background-color: get-color(quaternary);
			padding: 0rem 0.6rem;
			border-radius: 3rem;
			font-weight: 600;
			color: get-color(secondary);
			position: relative;
			display: flex;
			align-items: center;

			&.full {
				background-color: get-color(vol-green);
				color: #fff;
			}

			svg {
				margin-right: 0.25rem;
				width: 1.25rem;
			}
		}

		.waiting-list {
			color: darken(get-light-color(vol-green), 20%);
			font-weight: 700;
			padding-left: 0.5rem;
			display: inline-block;
		}
	}

	.waiting-list-user {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 0.2rem 0 0.5rem 0;

		.waiting-list-position {
			display: flex;
			width: 26px;
			height: 26px;
			background-color: get-color(quaternary);
			color: get-color(secondary);
			border-radius: 13px;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin-right: 0.5rem;
		}
	}
}

ul.spots {
	margin: 0.5rem 0rem;
	padding: 0rem;
	max-height: 25vh;
	overflow-y: scroll;

	li.spot {
		padding-bottom: 0.5rem;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;

		input {
			background-color: get-color(system-background);
			color: get-color(primary);

			&::-webkit-input-placeholder {
				color: get-color(tertiary);
			}
			&::-moz-placeholder {
				color: get-color(tertiary);
			}
			&:-ms-input-placeholder {
				color: get-color(tertiary);
			}
			&:-moz-placeholder {
				color: get-color(tertiary);
			}
		}

		.spot-image {
			width: 2rem;
			height: 2rem;
			overflow: hidden;
			border-radius: 50%;
			margin-right: 0.8rem;
			background-color: get-color(quaternary);

			img {
				height: 100%;
				min-width: 100%;
			}
		}
	}

	button.remove-spot {
		@extend .background-close-icon;
		height: 1.5rem;
		width: 1.5rem;
		border-radius: 50%;
		border: none;
		background-color: get-color(quaternary);
		transition: background-color 0.2s ease-in-out;

		&:hover,
		&:focus {
			outline: none;
			background-color: get-color(vol-red);
		}
	}

	input.spot-input {
		flex-grow: 1;
		margin-right: 0.8rem;
		border: none;

		&:focus {
			outline: none;
			border-bottom: 0.1rem solid get-color(vol-blue);
		}
	}
}

.address-remove {
	background-repeat: no-repeat;
	background-size: 1rem;
	background-position: center;
	background-color: get-color(vol-red-background);
	color: get-color(vol-red);
	background-image: url("../assets/svg/icon-remove-red.svg");
}

.slide-overview-controls {
	@extend .slide-overview-section;
	border: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	button {
		&:hover,
		&:focus {
			outline: none;
			opacity: 0.9;
		}

		&.edit {
			@extend .action-button-with-icon;
			background-image: url("../assets/svg/icon-edit-primary.svg");
		}

		&.copy {
			@extend .action-button-with-icon;
			background-image: url("../assets/svg/icon-copy-primary.svg");
		}

		&.remove {
			@extend .action-button-with-icon;
			background-color: get-color(vol-red-background);
			color: get-color(vol-red);
			background-image: url("../assets/svg/icon-remove-red.svg");
		}
	}
}
