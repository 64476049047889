.table-users {
    width: 100%;
    padding: 0 1rem;
    margin-bottom: 3rem;

    .custom-check-wrapper {
        padding: 0 0.3rem;

        .custom-check {
            width: 1.3rem;
            height: 1.3rem;
            border: 1px solid get-color(tertiary);
        }

        &.checked {
            .custom-check {
                border-color: get-color(vol-blue);
                background-size: 0.8rem;
                background-color: get-color(vol-blue-background);
                background-image: url(../assets/svg/icon-check-blue.svg);
            }
        }
    }

    thead {
        th {
            &:first-child {
                width: 3rem;
            }

            &:last-child {
                text-align: right;
            }

            .table-title {
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;

                span {
                    margin-right: 0.8rem;
                }

                div {
                    display: flex;
                    flex-direction: column;

                    .up {
                        margin-bottom: 0.2rem;
                    }

                    .down {
                        transform: rotate(180deg);
                    }

                    .up,
                    .down {
                        color: get-color(tertiary);

                        &.active {
                            color: get-color(vol-blue);
                        }

                        &.hide {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    tbody {
        tr {
            td {
                .edit,
                .delete {
                    cursor: pointer;
                    font-weight: 500;
                    font-size: 0.8rem;
                }

                &:last-child {
                    text-align: right;
                }

                .category {
                    margin-left: .6rem;
                    background: get-color(quaternary);
                    color: get-color(secondary);
                    text-transform: uppercase;
                    font-size: .7rem;
                    font-weight: 600;
                    padding: .3rem .4rem;
                    border-radius: 5px;
                    vertical-align: middle;
                }
            }
        }
    }
}
