.slide-overview {
	overflow: hidden;
	overflow-y: scroll;
	position: fixed;
	background-color: get-color(system-background-tertiary);
	box-shadow: -0.2rem 0rem 0.6rem 0rem rgba($color: #2C3F72, $alpha: 0.1);
	padding: 6.3rem 1rem 1rem 1rem;
	top: 0rem;
	right: -32rem;
	height: 100vh;
	width: 30rem;
	display: flex;
	flex-direction: column;
	align-items: stretch;
    justify-content: flex-start;
    transition: all ease-in-out .3s;
    z-index: 10;

	@media(prefers-color-scheme: dark) {
		box-shadow: none;
		border-left: .5px solid get-color(quaternary);
    }

    section {
        @extend .slide-overview-section;
    }

    &.active {
        right: 0;
    }

    // Checkbox control
    .custom-check-wrapper {
        &.checked {
            .custom-check {
                border-color: get-color(vol-blue);
                background-size: 0.8rem;
                background-color: get-color(vol-blue-background);
                background-image: url(../assets/svg/icon-check-blue.svg);
            }

        }

        .custom-check {
            width: 1.3rem;
            height: 1.3rem;
            border: 1px solid get-color(tertiary);
        }
    }

    // Radio control
    .form-check {
        label {
            padding-left: 1.25rem;
            margin: 0;

            .form-check-input {
                margin-top: .2rem;
            }
        }
    }

    .instructions {
        font-size: .9rem;
        color: get-color(secondary);
    }

    .notification-preview {
        display: flex;
        flex-direction: column;
        width: 80%;
        margin: 2rem auto;
        padding: .9rem;
        border-radius: 10px;
        background-color: get-color(system-background-secondary);
        font-size: .9rem;
        box-shadow: 0 0px 10px rgba(33, 57, 95, 0.15);

        .app {
            display: flex;
            justify-content: space-between;
            width: 100%;
            color: get-color(tertiary);

            img {
                width: 24px;
                margin-right: 12px;
                border-radius: 7px;
            }

            .name {
                text-transform: uppercase;
                letter-spacing: 1px;
                
            }
        }

        .title {
            font-weight: 600;
        }
    }
}

.slide-overview-section {
	padding: 1.5rem 0;
	border-bottom: .5px solid get-color(tertiary);

	h2 {
		font-weight: 600;
		font-size: 1.5rem;
		margin-top: 0;
	}

	h3 {
		font-size: 1.1rem;
		font-weight: 500;
		margin-top: 0;
	}

	p {
		margin-bottom: 0;
	}
}

.slide-overview-controls {
    .btn {
        flex-basis: 49%;

        &.edit,
        &.copy,
        &.remove {
            flex-basis: 32%;
        }
    }
}
