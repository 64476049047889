.print {
    .header {
        padding-bottom: 2rem;
        border-bottom: 0.5px solid get-light-color(quaternary);
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        img {
            max-width: 12rem;
        }

        .title {
            font-size: 2rem;
            font-weight: 600;
        }

        .subtitle {
            font-size: 1.25rem;
            font-weight: 500;
            color: get-color(vol-blue);
        }

        .print-date {
            color: get-light-color(secondary);
            text-align: right;
        }
    }
}
