$theme-blue: #475AE8;
$theme-red: #B10036;
$theme-green: #4BC88A;
$theme-purple: #5D167D;
$theme-brown: #BF8874;
$theme-light-blue: #6EA5E5;
$theme-orange: #FF9500;
$volontar-pink: rgb(198, 87, 105);

$color-black: #2B2B2B;
$color-light-black: #585858;
$color-primary: $theme-blue;
$color-light-grey: #EFEFEF;
$color-medium-grey: #e2e4e4;
$color-grey: #DDDDDD;
$color-dark-grey: #6A6A6C;
$system-grey-3: rgb(199, 199, 204);

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .aspect-ratio-content {
		position: absolute;
		width: 100%;
		height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}


/*
* ======
* Colors
* ======
*/

$white: #fff;
$light-grey: #b9b9b9;
$grey: #676767;
$dark-grey: #545663;
$black: #242424;
$blue: #4753e3;
$red: #ff3b30;
$light-red: #ff453a;

$primary: $blue;
$success: #34C759;