.category-table-header {
    @extend .user-table-header;
    justify-content: space-between;
}

table {
    &.categories-table {
        table-layout: fixed;

        td:nth-child(1),
        th:nth-child(1) {
            width: 20%;
        }

        td:nth-child(2),
        th:nth-child(2) {
            width: 50%;
        }
    }

    .category-row {
        td {
            vertical-align: middle;
            &.color-cell {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }

        .btn-wrapper,
        .edit-input,
        .btn-delete-wrapper {
            display: none;
            transition: all 0.2s ease-in-out;
        }

        &.edit-mode {
            background: get-color(system-background-tertiary);
            border: 1px solid get-color(quaternary);

            .table-action,
            .category-title {
                display: none;
            }

            .btn-wrapper,
            .color-picker,
            .edit-input {
                display: block;
                opacity: 1;
            }
        }

        &.delete-mode {
            background: get-color(system-background-tertiary);
            border: 1px solid get-color(quaternary);

            .table-action {
                display: none;
            }

            .btn-delete-wrapper {
                display: block;
                opacity: 1;
            }
        }
    }

    .cell-right {
        text-align: right;
    }
}

.color-cell-text {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 1rem;
}

.color-picker {
    margin: 0 0 0 2rem;
    opacity: 0;
    display: none;
    transition: all 0.2s ease-in-out;
}
