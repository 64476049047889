.manage-users {
    display: flex;
    flex-direction: row;
    justify-content: center;

    @import "../components/manage-users/SidebarManageUsers";

    .result-notification {
        display: flex;
        justify-content: flex-end;

        p {
            padding: 1rem;
            color: get-color(vol-blue);
            background-color: get-color(vol-blue-background);
            border-radius: 12px;
        }

        &.fail {
            p {
                color: get-color(vol-red);
                background-color: get-color(vol-red-background); 
            }
        }
    }

    .content-main {
        margin-left: 2rem;
        width: 1190px;

        .select-all-users {
            text-align: center;
            padding: 0.8rem 0;
            background: get-color(vol-blue-background);
            margin-bottom: 1rem;
            border-radius: 5px;
            font-size: 1rem;
            color: get-color(vol-blue);

            span {
                margin-left: 1rem;
                font-weight: 600;
                cursor: pointer;
            }
        }

        @import "../components/manage-users/TopbarManageUsers";
        @import "../components/manage-users/TableManageUsers";
    }
}