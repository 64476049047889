.statistics-container {
	padding-top: 10rem;
	.Dropdown-root {
		flex-grow: 0;
		width: 8rem;
	}
}

.statistics-head {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 2rem;
}

.statistics-row {
	margin: 0 -15px 3rem -15px;
	align-items: center;
	justify-content: flex-start;

	.statistics-box {
		@include aspect-ratio(1, 1);
		border-radius: 10px;

		.aspect-ratio-content {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}

		span {
			&.number {
				font-size: 4rem;
				font-weight: 600;
				margin: 0;
				text-align: center;
			}

			&.caption {
				display: inline-block;
				text-transform: uppercase;
				font-weight: 500;
				font-size: 1rem;
				text-align: center;
				max-width: 80%;
			}
		}

		&.blue {
			background-color: rgba($color: $theme-blue, $alpha: 0.1);
			color: $theme-blue;
		}

		&.purple {
			background-color: rgba($color: $theme-purple, $alpha: 0.1);
			color: $theme-purple;
		}

		&.brown {
			background-color: rgba($color: $theme-brown, $alpha: 0.1);
			color: $theme-brown;
		}

		&.light-blue {
			background-color: rgba($color: $theme-light-blue, $alpha: 0.1);
			color: $theme-light-blue;
		}
	}
}

.statistics-group-row {
	align-items: stretch;

	.group-box {
		background-color: get-color(system-background-tertiary);
		box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.12);
		border-radius: 0.6rem;
		padding: 1rem;
		margin-bottom: 3rem;

		h3 {
			text-transform: uppercase;
			font-size: 1rem;
			font-weight: 500;
			color: $theme-blue;
			width: 100%;
		}
	}
	.group-list {
		padding: 0;

		li {
			padding: .75rem 0;
			list-style: none;
			border-bottom: 1px solid get-color(quaternary);
			font-size: .9rem;
			display: flex;
			align-items: center;
			justify-content: space-between;

			&:nth-last-child(1) {
				border-bottom: none;
			}

			span {
				display: inline-block;
				font-weight: 600;
			}
		}
	}
}