// MARK: Abstract classes
.input-placeholder {
	color: get-color(quaternary);
	-webkit-transition: color 0.2s ease-in-out;
	transition: color 0.2s ease-in-out;
}

.form-control {
	border-color: inherit;
	box-shadow: none;
	background-color: get-color(system-background-tertiary);
	color: get-color(primary);

	&:focus {
		box-shadow: none;
		border-color: get-color(tertiary);
		background-color: get-color(system-background-tertiary);
		color: get-color(primary);
	}
}

.time-input {
	flex-basis: 5rem;
}


.flex-datepicker {
	flex-grow: 1;
	margin-right: .5rem;
	.react-datepicker-wrapper {
		display: block;
		.react-datepicker__input-container {
			display: block;
		}
	}
}

.flex-timepicker {
	flex-basis: 7rem;
}

.full-width-datepicker {
	display: flex;
	flex-direction: column;
	width: 100%;

	.react-datepicker-wrapper {
		display: block;
		.react-datepicker__input-container {
			display: block;
			margin-top: .5rem;
		}
	}
}

label.edit-label {
	text-transform: uppercase;
	color: get-color(secondary);
	padding: 0.5rem 0rem 0.2rem 0rem;
	margin: 0rem;
	font-size: .85rem;

	&:nth-of-type(1) {
		padding-top: 0rem;
	}
}

label.warning-label {
	color: get-color(vol-red);
	padding: 0.5rem 0rem 0.2rem 0rem;
	margin: 0rem;
	font-size: 1rem;

	&:nth-of-type(1) {
		padding-top: 0rem;
	}
}

input.edit-input {
	border-color: get-color(quaternary);

	&.smaller-width-input {
		flex-basis: 10rem;
	}
}

input.edit-input[type="number"] {
	width: 5rem;
	margin: 0 .5rem;
}

textarea.edit-input {
	border-color: get-color(quaternary);
	height: 10rem;
	resize: none;
}

div.form-check {
	margin-top: 1rem;
	color: get-color(primary);
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	padding-left: 0;
}

div.counter-container {
	margin-left: 0.8rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;

	span.counter-btn {
		width: 1.5rem;
		height: 1.5rem;
		border-radius: 1.5rem;
		background-color: get-color(quaternary);
		background-repeat: no-repeat;
		background-size: 0.8rem;
		background-position: center;
		cursor: pointer;
		transition: background-color 0.2s ease-in-out;

		&.subtract {
			background-image: url('../assets/svg/icon-subtraction.svg');
		}

		&.add {
			background-image: url('../assets/svg/icon-addition.svg');
		}

		&:hover,
		&:focus {
			background-color: get-color(vol-blue);
		}
	}
}

label.counter-label {
	margin: 0rem;
}

.spotSearch {
	list-style: none;
	padding: 0;
	margin-bottom: 15px;
	border-radius: 10px;
	background: get-color(system-background-tertiary);

	li {
		padding: 10px 20px;
		cursor: pointer;
		border-radius: 10px;
		color: get-color(secondary);

		&:hover {
			background: get-color(vol-blue-background);
		}
	}
}