.custom-check-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    margin: 0;

    input {
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        cursor: pointer;
    }

    .custom-check {
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.5rem;
        background-color: get-color(system-background-tertiary);
        border-radius: 0.2rem;
        cursor: pointer;
        padding: 0;

        &.custom-radio {
            &.checked {
                background-color: get-color(vol-blue);
            }

            border-radius: 25px;
            background-image: none;
            position: relative;

            .onSelect {
                background-color: #fff;
                position: absolute;
                display: block;
                width: 8px;
                height: 8px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 25px;
            }
        }
    }

    &.checked {
        &.is-minus {
            .custom-check {
                background-image: url("../assets/svg/minus-solid.svg")!important;
            }
        }

        .custom-check {
            background-color: get-color(vol-blue);
            border-color: transparent;
            background-image: url("../assets/svg/icon-check-primary.svg");
            background-repeat: no-repeat;
            background-size: 1rem;
            background-position: center;

            &.brown {
                background-image: url("../assets/svg/icon-check-brown.svg");
                background-color: rgba($color: $theme-brown, $alpha: 0.2);
            }

            &.purple {
                background-image: url("../assets/svg/icon-check-purple.svg");
                background-color: rgba($color: $theme-purple, $alpha: 0.2);
            }

            &.light-blue {
                background-image: url("../assets/svg/icon-check-light-blue.svg");
                background-color: rgba($color: $theme-light-blue, $alpha: 0.2);
            }
        }
    }
}