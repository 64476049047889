@import url('https://rsms.me/inter/inter.css');

$sans-serif-family: 'Inter',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

$mono-font: SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;

.mono-font {
    font-family: $mono-font !important;
}

body {
    font-family: $sans-serif-family;
    color: get-color(primary);
}

h1, h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
}

@supports (font-variation-settings: normal) {
    body {
        font-family: 'Inter var', sans-serif;
    }
}