.user-image {
    width: 50px;
    height: 50px;
    border-radius: 2rem;
    overflow: hidden;
    background-color: get-color(vol-blue);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1rem;

    span {
        color: white;
        font-size: 1.1rem;
        font-weight: 500;
    }
}