// Overriding the dropdown package default style
.Dropdown-control {
	border-radius: 4px;
	border: 1px solid get-color(quaternary);
	background-color: get-color(system-background-tertiary);
	padding: .35rem 3rem .35rem .75rem;
	color: get-color(primary);

	.Dropdown-arrow {
		top: 16px;
	}
}

.Dropdown-menu {
	border: 1px solid get-color(system-background-secondary);
	background-color: get-color(system-background-secondary);
	.Dropdown-option {
		color: get-color(primary);
		&.is-selected,
		&:hover {
			background-color: get-color(vol-blue-background);
		}
	}
}

.Dropdown-root {
	flex-grow: 1;
}