.login-page {
    * {
        padding: 0;
        box-sizing: border-box;
    }

    background: get-color(system-background-secondary);
    height: 100vh;

    .header {
        position: absolute;
        top: -226px;
        right: 0;
        left: calc((100vw - 585px) / 2);
        background: url('../assets/svg/header.svg') no-repeat;
        height: 316px;
        z-index: 0;
    }

    header {
        z-index: 1;
        position: relative;
        width: 100%;
        text-align: center;
        padding-top: 1.8rem;

        img {
            width: 146px;
        }
    }

    .container {
        margin-top: 3rem;
        font-family: $sans-serif-family;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 72vh;

        @include md {
            height: 87vh;
        }
    }

    .organisation {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .organisation-title {
            font-size: 1.3rem;
            color: get-color(primary);
            font-weight: normal;
            margin-bottom: 1rem;

            b {
                font-weight: bold;
            }

            &.is-success {
                color: get-color(vol-green);
                margin-top: 1.1rem;
            }

        }

        .organisation-logo {
            margin-bottom: 1rem;
            width: 100px;
            max-height: 120px;

            img {
                width: 100%;
                height: 100%;
            }

            &.hide {
                opacity: 0;
            }
        }

        .check-success {
            width: 60px;
            margin-top: 3rem;
        }

        .button-login {
            text-align: center;
            color: white;
            width: 150px;

            &:hover {
                color: white;
                text-decoration: none;
            }
        }
    }

    .policy {
        color: get-color(tertiary);
        text-align: center;
        font-size: 0.8rem;

        .button-pingst {
            display: block;
            font-family: $sans-serif-family;
            font-size: 1rem;
            background-color: get-color(vol-blue);
            width: 290px;
            color: white;
            font-weight: 400;
            border-radius: 10px;
            height: 40px;
            line-height: 40px;
            border: none;
            margin: 0 auto 1rem auto;
            cursor: pointer;

            .button-pingst-icon {
                margin-left: 0.8rem;
                width: 12px;
                fill: white;
            }

            &:hover {
                text-decoration: none;
            }
        }

        .transition {
            margin-bottom: 1rem;

            span {
                color: get-color(primary);
                text-transform: uppercase;
                font-size: 0.9rem;
                font-weight: 600;
                padding-left: 1rem;
                padding-right: 1rem;
            }

            div {
                width: 103px;
                display: inline-block;
                vertical-align: middle;
                height: 1px;
                background: get-color(primary);
            }
        }
    }

    .form-login {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        @include md {
            width: 300px;
        }

        .forgot-password {
            margin-top: 2rem;
            opacity: 0;
            cursor: pointer;
            margin-bottom: 0rem;

            a {
                color: get-color(primary);
                font-size: 0.9rem;
                font-weight: 400;

                span {
                    padding-top: 4px;
                }

                &:hover {
                    color: get-color(primary);
                    text-decoration: none;
                }
            }

            &.show {
                opacity: 1;
            }

            .forgot-password-icon {
                margin-left: 0.5rem;
                width: 12px;
                fill: get-color(primary);
                display: inline-block;
            }
        }

        .error-message {
            color: get-color(vol-red);
            text-align: left;
            width: 100%;
            font-size: 0.7rem;
            text-transform: uppercase;
            font-weight: 600;
            margin-top: 0.5rem;
            padding-left: 0.8rem;
            margin-bottom: 0.5rem;
        }

        input:first-child {
            border-radius: 6px 6px 0px 0px;
        }

        input:last-child {
            border-radius: 0px 0px 6px 6px;
        }

        .input-container {
            position: relative;
            width: 100%;

            .arrow-right {
                width: 1.6rem;
                position: absolute;
                right: 0.5rem;
                top: 50%;
                transform: translateY(-50%);
                fill: get-color(vol-blue);
                cursor: pointer;

                &.has-error {
                    fill: get-color(vol-red);
                }
            }

            input {
                font-family: $sans-serif-family;
                background: get-color(system-background-tertiary);
                color: get-color(primary);
                border: 1px solid get-color(tertiary);
                height: 46px;
                font-size: 1rem;
                padding: 0 0.8rem;
                font-weight: 400;
                -webkit-appearance: none;
                width: 100%;


                outline: none;
                &:focus {
                    outline: 0;
                }

                &::placeholder {
                    text-transform: none;
                    color: get-color(quaternary);
                }

                &.field-is-alone {
                    border-radius: 6px;
                }

                &.field-is-first {
                    border-radius: 6px 6px 0px 0px;
                    border-bottom: 0;
                }

                &.field-is-last {
                    border-top-color: get-color(quaternary);
                    border-radius: 0px 0px 6px 6px;
                    height: 0px;
                    opacity: 0;
                }

                &.field-transition {
                    transition: 0.2s all;
                    height: 46px;
                    opacity: 1;
                }

                &.field-has-error {
                    background: rgba($color: get-light-color(vol-red), $alpha: .1);
                    color: get-color(vol-red);
                    border-color: get-color(vol-red);
                }
            }
        }

    }

    .button-login {
        font-family: $sans-serif-family;
        margin-top: 2rem;
        font-size: 1rem;
        background: get-color(vol-blue);
        width: 120px;
        color: white;
        font-weight: 600;
        border-radius: 30px;
        height: 40px;
        line-height: 40px;
        border: none;
        opacity: 0;
        text-transform: uppercase;
        cursor: pointer;

        &.button-show {
            opacity: 1;
            transition: 0.2s all;
        }

        &:focus {
            outline: 0;
            border: none;
        }
    }
}

.create-password {
    .organisation {
        .organisation-title {
            margin-top: 3rem;
            margin-bottom: 2rem;
            text-align: center;
        }
    }

    .form-login {
        .button-login {
            width: 130px;
        }
    }
}